import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CurrencyCode } from 'src/app/modules/bookkeeping/enums/currency-code';
import { UpdatedSettings } from 'src/app/modules/bookkeeping/models/settings/updated-settings';
import { TaxRate } from 'src/app/modules/bookkeeping/models/taxRate';
import { TrackingCategory } from 'src/app/modules/bookkeeping/models/tracking-categories.ts/trackingCategory';
import { BatchPaymentHeader } from 'src/app/modules/payable/models/batch-payment-header-model';
import { OrganizationShortData } from 'src/app/modules/signup/models/organization-short-data';
import { environment } from 'src/environments/environment';
import { RefreshLog } from '../../modules/setting/integration/integration-sidebar/model/refreshlog';
import { HttpInternalService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  organizationList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedOrganizationId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  contactlist: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  organizationTrackingCategories: BehaviorSubject<TrackingCategory[]> = new BehaviorSubject<TrackingCategory[]>([]);
  trackingCategoriesState$ = this.organizationTrackingCategories.asObservable();

  organizationForEdit: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  organizationForEditState$ = this.organizationForEdit.asObservable();

  companyEditModalSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  companyEditModalState$ = this.companyEditModalSubject.asObservable();

  constructor(private _httpService: HttpInternalService) { }

  get selectedOrganizationDateFormat() {
    return sessionStorage.getItem('orgDateFormat');
  }


  set reloadData(value: string) {
    sessionStorage.setItem('orgDateFormat', value);
  }


  set selectedOrganizationDateFormat(value: string) {
    sessionStorage.setItem('orgDateFormat', value);
  }


  get selectedOrganizationDomen() {
    return sessionStorage.getItem('orgDomen');
  }

  set selectedOrganizationDomen(value: string) {
    sessionStorage.setItem('orgDomen', value);
  }

  set selectedOrganization(data: string) {
    localStorage.setItem('SelectedOrganization', data);
  }

  get selectedOrganization(): string {
    return localStorage.getItem('SelectedOrganization');
  }

  set selectedOrganizationName(data: string) {
    localStorage.setItem('SelectedOrganizationName', data);
  }

  get selectedOrganizationName(): string {
    return localStorage.getItem('SelectedOrganizationName');
  }

  set OrganizationUseAzureAPI(data: string) {
    localStorage.setItem('OrganizationUseAzureAPI', data);
  }
  get OrganizationUseAzureAPI(): string {
    return localStorage.getItem('OrganizationUseAzureAPI');
  }

  getOrganization(url: string) {
    return this._httpService.getRequest(url);
  }

  getSelectedOrganization(OrganizationId?) {
    // this.getContactList(OrganizationId);
    this.selectedOrganizationId.next(OrganizationId ? OrganizationId : null);
  }

  setTrackingCategories(categories: TrackingCategory[]) {
    this.organizationTrackingCategories.next(categories);
  }

  setCompanyEdit(company: any) {
    this.organizationForEdit.next(company);
  }

  getOrganizationEmail(organizationId: string) {
    return this._httpService.getRequest<{ email: string }>(`Organisation/getOrganizationEmail?id=${organizationId}`);
  }

  getOrganizationTaxRateId(organizationId: string) {
    return this._httpService.getRequest<{ taxRateId: string, taxExclusiveRateId: string, taxBasis: number, countryCode?: string }>(`Organisation/getOrganizationTaxRateId?id=${organizationId}`);
  }

  getOrganizationTrackingCategories(organizationId: string) {
    return this._httpService.getRequest<string[]>(`TrackingCategories/getOrganizationTrackingCategories?id=${organizationId}`);
  }

  getFullOrganizationTrackingCategories(organizationId: string) {
    return this._httpService.getRequest<TrackingCategory[]>(`TrackingCategories/getFullOrganizationTrackingCategories?id=${organizationId}`);
  }

  getOrganizationTrackingCategoriesWithOptions(id: string) {
    return this._httpService.getRequest<TrackingCategory[]>(`TrackingCategories/getOrganizationTrackingCategoriesWithOptions?id=${id}`);
  }

  getOrganizationDefaultCurrency(id: string) {
    return this._httpService.getRequest<{ currency: CurrencyCode }>(`Organisation/getOrganizationDefaultCurrency?id=${id}`);
  }

  getOrganizationCountry(id: string) {
    return this._httpService.getRequest<{ countryCode: string }>(`Organisation/getOrganizationCountry?id=${id}`);
  }

  updateOrganizationSettings(updatedSettings: UpdatedSettings) {
    return this._httpService.putRequest('Organisation/updateOrganizationSettings', updatedSettings);
  }

  getContactList(url: string, organizationId: any, isCustomer: any, isSupplier: any, contactId: any) {
    return this._httpService.getRequest(`${url + '?Id=' + organizationId + '&IsCustomer=' + isCustomer + '&IsSupplier=' + isSupplier + '&ContactId=' + contactId}`);
  }

  getOutstandingPayableReportList(OrganizationId, userid, startDate, endDate, ReportPeriod) {
    return this._httpService.getRequest<any>(`${'Reporting/GetOutstandingPayableReportList' + '?OrganizationId=' + OrganizationId + '&userid=' + userid + "&startDate=" + startDate + "&endDate=" + endDate + "&ReportPeriod=" + ReportPeriod}`);
  }

  getUnreconciledItemsReportList(OrganizationId, userid, startDate, endDate) {
    return this._httpService.getRequest<any>(`${'Reporting/GetUnreconciledItemsReportList' + '?OrganizationId=' + OrganizationId + "&userid=" + userid + "&startDate=" + startDate + "&endDate=" + endDate}`);
  }

  GetReportListByUser(userid, OrganizationId) {
    return this._httpService.getRequest<any>(`${'Reporting/GetReportListByUser' + "?userid=" + userid + "&OrganisationId=" + OrganizationId}`);
  }

  GetUnreconciledReportListByReport(ReportId, OrganisationID, userId) {
    return this._httpService.getRequest<any>(`${'Reporting/GetUnreconciledReportListByReport' + '?ReportId=' + ReportId + "&OrganisationID=" + OrganisationID + "&userId=" + userId}`);
  }

  GetOutstandingPayablesListByReport(ReportId, OrganizationId, userId) {
    return this._httpService.getRequest<any>(`${'Reporting/GetOutstandingPayablesListByReport' + '?ReportId=' + ReportId + "&OrganizationId=" + OrganizationId + "&userId=" + userId}`);
  }

  InsertUpdateReportsComments(commentdetailsModel) {
    var modaldata = JSON.stringify(commentdetailsModel);
    return this._httpService.postRequest<any>('Reporting/InsertUpdateReportsComments', modaldata);
  }

  GetTaskReportDetails(userid, OrganizationId, Status) {
    return this._httpService.getRequest<any>(`${'Reporting/GetTaskReportDetails?userId=' + userid + '&organisationId=' + OrganizationId + '&Status=' + Status}`);
  }

  GetAllOrganization(url: string) {
    return this._httpService.getRequest<any>(url);
  }

  DeleteOrganization(OrganizationId, organisationType) {
    return this._httpService.getRequest<any>(`${'Organisation/DeleteOrganisationById' + '?id=' + OrganizationId + '&organisationType=' + organisationType}`);
  }

  OrganisationActivationOrDeletion(organizationId: string, status: boolean) {
    return this._httpService.getRequest<any>(`${'Organisation/UpdateOrganisationStatus' + "?id=" + organizationId + "&IsActive=" + status}`);
  }

  UpdateOrganisationAzureAPI(organizationId: string, status: boolean) {
    return this._httpService.getRequest<any>(`${'Organisation/UpdateOrganisationAzureAPI' + "?id=" + organizationId + "&UseAzureAPI=" + status}`);
  }

  EditOrganisation(url: string, organisationModel) {
    return this._httpService.postRequest<any>(url, JSON.stringify(organisationModel));
  }

  GetUnreconcileCommentsReportDetails(unreconciledItemList, CommentsId) {
    var reporturl = "Reporting/" + CommentsId + "/GetUnreconcileCommentsReportDetails";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(unreconciledItemList));
  }

  GetUnreconcileCommentsSaveReportDetails(unreconciledItemList, CommentsId) {
    var reporturl = "Reporting/" + CommentsId + "/GetUnreconcileCommentsSaveReportDetails";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(unreconciledItemList));
  }

  GetoutstandingPayablesCommentsReportDetails(unreconciledItemList, CommentsId) {
    var reporturl = "Reporting/" + CommentsId + "/GetOutstandingPayablesCommentsReportDetails";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(unreconciledItemList));
  }

  DeleteReportDetails(ReportId) {
    return this._httpService.getRequest<any>(`${'Reporting/DeleteReportDetails' + '?ReportId=' + ReportId}`);
  }

  GetBankAccountDetails(url: string, organisationId) {
    return this._httpService.getRequest<any>(`${url + '?organisationId=' + organisationId}`);
  }

  GetCardDetails(url: string, accountId) {
    return this._httpService.getRequest<any>(`${url + '?accountId=' + accountId}`);
  }

  EditCompany(url: string, organisationModel) {
    return this._httpService.postRequest<any>(url, JSON.stringify(organisationModel));
  }

  OutstandingBatchPayment(OrganizationId, BatchPayments) {
    var reporturl = "Reporting/" + OrganizationId + "/OutstandingBatchPayment";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(BatchPayments));
  }

  OutstandingBatchPaymentFromInbox(OrganizationId, BatchPayments) {
    var reporturl = "Reporting/" + OrganizationId + "/OutstandingBatchPaymentFromInbox";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(BatchPayments));
  }

  saveBatchPayment(batchPayment: BatchPaymentHeader) {
    return this._httpService.postRequest<string>('Reporting/SaveBatchPayment', batchPayment);
  }

  deleteBatchPayment(paymentId: string) {
    return this._httpService.deleteRequest(`Reporting/DeleteBatchPayment?paymentId=${paymentId}`);
  }

  GetOrganisationsDetailsByUsersLoginId(organizationId: string) {
    return this._httpService.getRequest<any>(`${'Organisation/GetOrganisationsDetailsByUsersLoginId' + "?OrganisationId=" + organizationId + "&userId=" + sessionStorage.Id}`);
  }

  GetOrganisationsDocumentLimit(organizationId: string) {
    return this._httpService.getRequest<any>(`${'Organisation/GetOrganisationsDocumentLimit' + "?OrganisationId=" + organizationId}`);
  }

  SaveOutstandingPayableReportName(params) {
    return this._httpService.postRequest<any>(`${'Reporting/SaveOutstandingPayableReportName'}`, JSON.stringify(params));
  }

  GetReportName(reportId: string, organizationId: string) {
    return this._httpService.getRequest<any>(`${'Reporting/GetReportName' + "?OrganisationId=" + organizationId + "&ReportId=" + reportId}`);
  }

  SetDefaultAccount(accountId, organisationId) {
    return this._httpService.getRequest<any>(`${'Organisation/SetDefaultAccount' + "?accountId=" + accountId + "&organisationId=" + organisationId}`);
  }

  SetRemoveDefaultAccount(accountId, organisationId, isDefaultAccount) {
    return this._httpService.getRequest<any>(`${'Organisation/SetRemoveDefaultAccount' + "?accountId=" + accountId + "&organisationId=" + organisationId + "&isDefault=" + isDefaultAccount}`);
  }

  UpdateAssignToAccount(accountId, organisationId, userId) {
    return this._httpService.getRequest<any>(`${'Organisation/UpdateAssignToAccount' + "?accountId=" + accountId + "&organisationId=" + organisationId + "&userId=" + userId}`);
  }

  SetLimitsForDoc(usageLimit, userLimit, organisationId, modelId) {
    return this._httpService.getRequest<any>(`${'Organisation/SetLimitsForDoc' + "?usageLimit=" + usageLimit + "&userLimit=" + userLimit + "&organisationId=" + organisationId + "&modelId=" + modelId}`);
  }

  GetOutstandingPayablesCommentsSaveReportDetails(unreconciledItemList, CommentsId) {
    var reporturl = "Reporting/" + CommentsId + "/GetOutstandingPayablesCommentsSaveReportDetails";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(unreconciledItemList));
  }

  SaveExcludeItem(ReportId, excludeData) {
    var reporturl = "Reporting/" + ReportId + "/SetExludeOutstandingPayableRecord";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(excludeData));
  }

  UpdateStatusOfTask(TaskId, Complete) {
    return this._httpService.getRequest<any>(`${'Dashboard/UpdateStatusOfTask?TaskId=' + TaskId + "&Complete=" + Complete}`);
  }

  GetAllBillsToPayItems(reportDataModel) {
    var reporturl = "BillsToPay/GetAllBillsToPayItems";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(reportDataModel));
  }

  GetCommentsDetails(reportDataModel) {
    var reporturl = "Reporting/GetCommentsDetails";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(reportDataModel));
  }

  GetBatchPaymentList(organisationId) {
    return this._httpService.getRequest<any>(`${'Reporting/GetBatchPaymentList' + "?organisationId=" + organisationId}`);
  }

  SetPayableReportDataSelected(supplier, contactId, isChecked, reportId) {
    return this._httpService.getRequest<any>(`${'Reporting/SetPayableReportDataSelected?supplier=' + supplier + "&contactId=" + contactId + "&isChecked=" + isChecked + "&reportId=" + reportId}`);
  }

  SetPayableReportDataSelectedByInvoiceNumber(reportDataModel) {
    return this._httpService.postRequest<any>(`${'Reporting/SetPayableReportDataSelectedByInvoiceNumber'}`, JSON.stringify(reportDataModel));
  }

  SetPayableReportSelectedByReportId(isChecked, reportId) {
    return this._httpService.getRequest<any>(`${'Reporting/SetPayableReportSelectedByReportId?isChecked=' + isChecked + "&reportId=" + reportId}`);
  }

  GetBankAccountDetailsBySupplier(contactId, organizationId: string) {
    return this._httpService.getRequest<any>(`${'Reporting/GetBankAccountDetailsBySupplier?contactId=' + contactId}&organizationId=${organizationId}`);
  }

  UpdateBankAccount(bankAccountModel) {
    return this._httpService.postRequest<any>('Reporting/UpdateBankAccount', JSON.stringify(bankAccountModel));
  }

  CreateBankAccountDetails(bankAccountModel) {
    return this._httpService.postRequest<any>('Reporting/CreateBankAccount', JSON.stringify(bankAccountModel));
  }

  CreateSyncedAccountDetails(bankAccountModel) {
    return this._httpService.postRequest<any>('Reporting/CreateSyncedAccount', JSON.stringify(bankAccountModel));
  }

  CreateCardDetails(bankAccountModel) {
    return this._httpService.postRequest<any>('Reporting/CreateCard', JSON.stringify(bankAccountModel));
  }

  UpdateOutstandingPaymentAmount(OutstandingPayablesRequestModel) {
    var reporturl = "Reporting/UpdateOutstandingPaymentAmount";
    return this._httpService.postRequest<any>(reporturl, JSON.stringify(OutstandingPayablesRequestModel));
  }

  InsertApprovals(commentdetailsModel) {
    var modaldata = JSON.stringify(commentdetailsModel)
    return this._httpService.postRequest<any>('OutstandingPayable/InsertApprovals', modaldata);
  }

  CreateNewUser(newUserRequest) {
    return this._httpService.postRequest<any>('Reporting/CreateNewUser', JSON.stringify(newUserRequest));
  }

  GetSavedReportByReportId(reportDataModel) {
    var reporturl = environment.apiURL + "Reporting/GetSavedReportByReportId";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(reportDataModel));
  }

  GetUnreconciledReportData(reportDataModel) {
    var reporturl = environment.apiURL + "Reporting/GetUnreconciledReportData";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(reportDataModel));
  }


  GetUnreconciledReportList(reportDataModel) {
    var reporturl = environment.apiURL + "Reporting/GetUnreconciledReportList";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(reportDataModel));
  }

  sendAssignedNotification(reportDataModel) {
    var reporturl = environment.apiURL + "Reporting/sendAssignedNotification";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(reportDataModel));
  }

  saveTransactionStatus(reportDataModel) {
    var reporturl = environment.apiURL + "Reporting/SaveBankTransactionAssignStatus";
    return this._httpService.postRequest<any>(`${reporturl}`, reportDataModel);
  }


  InsertUpdateUserLastLoggedOrganization(UserId, OrganizationId) {
    return this._httpService.getRequest<any>(`${'Organisation/InsertUpdateUserLastLoggedOrganization?UserId=' + UserId + "&OrganizationId=" + OrganizationId}`);
  }

  GetUserLastLoggedOrganization(UserId) {
    return this._httpService.getRequest<any>(`${'Organisation/GetUserLastLoggedOrganization?UserId=' + UserId}`);
  }

  GetOrgUserLoggedOrganization(UserId, organizationId) {
    return this._httpService.getRequest<any>(`${'Organisation/GetUserLoggedOrganization?UserId=' + UserId + '&organizationId=' + organizationId}`);
  }

  getTaxRates(organizationId: string, isSettings: boolean = false) {
    return this._httpService.getRequest<TaxRate[]>(`TaxRates/getTaxRates?id=${organizationId}&isSettings=` + isSettings);
  }

  getTrackingCategories(organizationId: string) {
    return this._httpService.getRequest<TrackingCategory[]>(`TrackingCategories/getTrackingCategories?id=${organizationId}`);
  }

  UpdateScheduleDateBills(reportDataModel) {
    var reporturl = "BillsToPay/Update_ScheduleDateBills";
    return this._httpService.postRequest<any>(`${reporturl}`, JSON.stringify(reportDataModel));
  }

  RemoveXeroConnection(OrganizationId, organisationType, userId: string = '', gmailAccountId: number = 0) {
    return this._httpService.getRequest<any>(`${'Organisation/RemoveXeroConnection' + '?id=' + OrganizationId + '&organisationType=' + organisationType + (userId != '' ? '&userId=' + userId : '') + (gmailAccountId != 0 ? '&gmailAccountId=' + gmailAccountId : '')}`);
  }

  RefreshXeroConnection(OrganizationId) {
    return this._httpService.getRequest<any>(`${'Organisation/RefreshXeroConnection' + '?id=' + OrganizationId}`);
  }

  getOrganizationCurrencies(organizationId: string) {
    return this._httpService.getRequest<string[]>(`${'Organisation/GetOrganizationCurrencies' + '?organisationId=' + organizationId}`);
  }

  getOrganizationShortData(organizationId: string) {
    return this._httpService.getRequest<OrganizationShortData>(`${'Organisation/GetOrganizationShortData' + '?organisationId=' + organizationId}`);
  }

  getOrganizationLastRefreshLog(organizationId: string) {
    return this._httpService.getRequest<RefreshLog[]>(`${'Organisation/GetOrganizationLastRefreshLog' + '?organisationId=' + organizationId}`);
  }

  updateOrganizationLastRefreshLog(organizationId: string, email: string) {
    var modaldata =
    {
      organizationId: organizationId,
      email: email
    }
    return this._httpService.getRequest<any>(`${'Organisation/UpdateOrganizationLastRefreshLog?organisationId=' + organizationId + '&email=' + email}`, email);
  }

  SyncNoAccountingData(model: any) {

    return this._httpService.postRequest<any>(`${'Organisation/SyncNoAccountingData'}`, JSON.stringify(model));
  }

  getAccountsList(organizationId: string) {
    return this._httpService.getRequest('Organisation/OrganisationAccountsList' + "?organisationId=" + organizationId);
  }

  getTaxRatesList(organizationId: string) {
    return this._httpService.getRequest('Organisation/OrganisationTaxRatesList' + "?organisationId=" + organizationId);
  }

  getCurrenciesList(organizationId: string) {
    return this._httpService.getRequest('Organisation/OrganisationCurrenciesList' + "?organisationId=" + organizationId);
  }

  getTrackingList(organizationId: string) {
    return this._httpService.getRequest('Organisation/OrganisationTrackingList' + "?organisationId=" + organizationId);
  }

  getBankingList(organizationId: string) {
    return this._httpService.getRequest('Organisation/OrganisationBankingList' + "?organisationId=" + organizationId);
  }
}
