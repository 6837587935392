import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { InvoiceService } from 'src/app/data/service/invoice.service';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { SuppliersService } from 'src/app/data/service/suppliers.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { ConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { InvoiceActivity } from '../../models/activity/activity';
import { UserService } from 'src/app/data/service/user.service';
import { NotesComponent } from '../notes/notes.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { take } from 'rxjs/operators';
import { RecognizedInvoiceService } from '../../services/recognized-invoice.service';
import {
  InvoiceUnReconcileShort,
  InvoiceUnReconciledAdd,
} from '../../models/invoice/invoice-short';
import { CreatedInvoiceWithLines } from '../../models/invoice/created-invoice-with-lines';
import { CreditNoteDTO } from '../../models/credit-note/credit-note';
import { BankTransactionDTO } from '../../models/expense/bank-transactions';
import { Subscription } from 'rxjs';
import { AccountingIntegration } from 'src/app/data/enums/accounting-integration';
import { Invoice } from '../../models/invoice/invoice';
import { PaymentAccount } from '../../models/account/payment-account';
import { Pannels } from '../../enums/sidebar-pannels';
import { NewSupplier } from '../../models/supplier/new-supplier';
import { SupplierShort } from '../../models/supplier/supplier-short';
import { CurrencyCode } from '../../enums/currency-code';
import { SupplierWithAlias } from '../../models/supplier/supplier-with-alias';
import { BankAccount } from '../../models/account/bank-account';
import { BankAccountService } from 'src/app/data/service/bank-account.service';
import {
  QuickBookConnection,
  xeroParamsForConnection,
} from 'src/environments/environment';
import { tryGetObjectByProperty } from 'src/app/core/util/get-object-by-property';
import * as $ from 'jquery';
import { Guid } from 'guid-typescript';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaxBasis } from '../../enums/taxBasis';
import { BillsToPayInvoiceDetailsRequest } from '../../models/invoice/bills-to-pay-invoice-details-request';
import { debug } from 'console';
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent extends BaseComponent implements OnInit {
  @Output() moveBack = new EventEmitter<void>();
  @Output() deleteTrans = new EventEmitter<string>();
  @Output() assignTransa = new EventEmitter<object>();
  @Output() transNotes = new EventEmitter<object>();
  @Output() refreshDataOnMatch = new EventEmitter<object>();
  @Input() supplierName: string = '';
  @Input() invoiceId: string = null;
  @Input() supplierId: string = null;
  @Input() isInvoiceMode: boolean = true;
  @Input() invoiceColorNumber: number = 1;
  @Input() isBankTransMode: boolean = false;
  @Input() bankTransArr: any = [];
  currentPannel: Pannels = Pannels.DetailsTab;
  noteType: string = '';
  recordId: string = '';
  showToolTipDelay: number = 400;
  activities = [] as InvoiceActivity[];
  isSpinnerShown: boolean = false;
  isActivityExpanded: boolean = true;
  isNoteExpanded: boolean = true;
  organizationId: string = null;
  usersList: any[] = [];
  unreconciledData: InvoiceUnReconcileShort[] = [];
  reconcileData: InvoiceUnReconcileShort;
  isMatchSelected: boolean = false;
  isFileUploadProcessing: boolean = false;
  IsQuickBook: boolean = false;
  IsNoAccounting: boolean = false;
  curIdx: number = 0;
  shownCategoriesInvoiceId: number = 1;
  subscriptions: Subscription[] = [];
  panels = Pannels;
  invoice: Invoice = new Invoice(
    this._organizationService.selectedOrganization
  );
  creditNote: CreditNoteDTO = new CreditNoteDTO(
    this._organizationService.selectedOrganization
  );
  bankTransation: BankTransactionDTO = new BankTransactionDTO(
    this._organizationService.selectedOrganization
  );
  // row ={
  //   rowCount:1,supplierName:'Demo Company (AU)',hexColorClass:"sup_img_box_1",shortCode:"JD",isDemo:false,contactStatus:true,invoiceNumber:'123456',accountName:"Donations",total:123,amountDue:234,date:new Date('2024-03-12'),submissionMethod:3
  // }
  invoiceLines: any = [];
  lineItemList = [];
  isStatusChg: boolean = false;
  noteMentions: any[] = [];
  bankAccountList: PaymentAccount[] = [];
  type: string = '';
  isManualPaperwork: boolean = false;
  isNewSupplierLoaded: boolean = false;
  selectedSupplierName: string;
  isNewSupplier: boolean = false;
  newSupplier: NewSupplier = new NewSupplier(
    this._organizationService.selectedOrganization
  );
  creatingNewSupplier: boolean = false;
  isCreateSupplierItemShown: boolean = false;
  suppliers: SupplierShort[] = [];
  currentSupplier: SupplierWithAlias = new SupplierWithAlias();
  bankAccount: BankAccount = new BankAccount(
    this._organizationService.selectedOrganization
  );
  defaultCurrency: CurrencyCode;
  account: PaymentAccount = new PaymentAccount();
  accounts: PaymentAccount[] = [];
  supplierSearchList: SupplierShort[] = [];
  isFormValidated: boolean = false;
  isManualSaved: boolean = false;
  addDocumentForm: FormGroup;
  reconciledDataAdd: InvoiceUnReconciledAdd;
  selectedSupplierId: string;
  organizationTaxRateId: string;
  orgTaxBasis: TaxBasis = TaxBasis.DefaultRate;
  description: string = '';
  isSaving: boolean = false;
  showInvoiceFormPanel: boolean = false;
  invoiceEditable: boolean = false;

  @ViewChild('addDocumentModel') addDocumentModel: ModalDirective;
  get addDocumentFormControl() {
    return this.addDocumentForm.controls;
  }
  matchProcessing: boolean = false;
  @ViewChild('noteinput') noteinput: ElementRef;
  @ViewChild('commentModel') commentModel: ModalDirective;
  constructor(
    private readonly _invoiceService: InvoiceService,
    private readonly _toasterMessageService: ToasterMessageService,
    private readonly _organizationService: OrganizationService,
    private readonly _supplierService: SuppliersService,
    private _userService: UserService,
    private confirmationDialogService: ConfirmationDialogService,
    private _recognizedInvoiceService: RecognizedInvoiceService,
    private _bankAccountService: BankAccountService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    super();
  }
  @ViewChild(NotesComponent) notesComponent: NotesComponent;
  @HostListener('click', ['$event'])
  onClick(event): void {
    this.notesComponent?.hideEmoji(event);
  }
  ngOnInit(): void {
    this.addDocumentForm = this.formBuilder.group({
      supplier: [null, Validators.required],
      account: [
        { organizationId: this._organizationService.selectedOrganization },
        Validators.required,
      ],
      description: [null, Validators.required],
    });

    this.setIsQuickBookOrNot();
    this.GetBankAcoountDetails();
    if (this.isInvoiceMode) {
      this.isSpinnerShown = true;

      this._invoiceService
        .getInvoiceActivityById(
          this.invoiceId,
          this._organizationService.selectedOrganization
        )
        .pipe(this.untilThis)
        .subscribe(
          (data: InvoiceActivity[]) => {
            this.activities = this.activities.concat(data);
            let actors = [] as string[];
            this.activities?.forEach((element) => {
              if (actors?.length == 0) {
                actors.push(element.actor);
              } else if (!actors.includes(element.actor)) {
                actors.push(element.actor);
              }
              element.visible = false;
            });

            if (actors && actors.length > 0) {
              this.activities.forEach((element) => {
                element.colorNumber = actors.indexOf(element.actor) + 1;
              });
            }
            this.isSpinnerShown = false;
          },
          (e: Error) => {
            this.isSpinnerShown = false;
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    } else if (this.isBankTransMode) {
      this.organizationId = this._organizationService.selectedOrganization;
      this.isSpinnerShown = true;
      this.activities = this.bankTransArr;
      this.invoiceId = this.activities[this.curIdx]['unreconciledReportId'];
      this.getOrganizationDefaultCurrency();
      this.getSelectedTaxRate();
      this.getAccounts();
      this.getSuppliers();
      this.getRelatedData();
    } else {
      this.isSpinnerShown = true;
      this._supplierService
        .getSupplierActivityById(
          this._organizationService.selectedOrganization,
          this.supplierId
        )
        .pipe(this.untilThis)
        .subscribe(
          (data) => {
            this.activities = this.activities.concat(
              data.filter((a) => a.date != null)
            );
            this.isSpinnerShown = false;
          },
          (e: Error) => {
            this.isSpinnerShown = false;
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    }

    this.setNoteType();
  }

  getOrganizationDefaultCurrency() {
    this._organizationService
      .getOrganizationDefaultCurrency(
        this._organizationService.selectedOrganization
      )
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.defaultCurrency = response.currency;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(
            ToasterTypes.Error,
            'Error',
            e.message
          );
        }
      );
  }

  getSelectedTaxRate() {
    this._organizationService
      .getOrganizationTaxRateId(this._organizationService.selectedOrganization)
      .pipe(this.untilThis)
      .subscribe(
        (response) => {
          if (
            (this.IsQuickBook || this.IsNoAccounting) &&
            response.taxBasis == TaxBasis.IgnoreTax
          ) {
            this.orgTaxBasis = response.taxBasis;
            this.organizationTaxRateId = '0';
          } else {
            this.organizationTaxRateId = response.taxRateId;
          }
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(
            ToasterTypes.Error,
            'Error',
            e.message
          );
        }
      );
  }

  setIsQuickBookOrNot() {
    this.subscriptions.push(
      this._organizationService.organizationList.subscribe((data) => {
        const org = data?.find(
          (o) =>
            o.organisationID === this._organizationService.selectedOrganization
        );
        this.IsQuickBook =
          org?.accountingIntegration === AccountingIntegration.QBO
            ? true
            : false;
        this.IsNoAccounting =
          org?.accountingIntegration === AccountingIntegration.None
            ? true
            : false;
      })
    );
  }
  onScroll(event: any) {
    if (document.querySelector('#activitydiv').scrollTop < 70) {
      // document.getElementsByClassName("transIcon")[0].style.top=20;
      const highlights = document.getElementsByClassName('transIcon');
      for (let i = 0; i < highlights.length; i++) {
        const element = highlights[i] as HTMLElement;
        element.style.top =
          16 - document.querySelector('#activitydiv').scrollTop + 'px';
      }
    }
  }

  getRelatedData() {
    this.isSpinnerShown = true;
    if (this.activities[this.curIdx]['relatedID'] != '') {
      this.relatedID = this.activities[this.curIdx]['relatedID'];
      this.relatedName = this.activities[this.curIdx]['relatedName'];

      this._invoiceService
        .getRelatedData(
          this._organizationService.selectedOrganization,
          this.activities[this.curIdx]['relatedID']
        )
        .subscribe((data: any) => {
          this.invoiceLines = data;
          this.isFileUploadProcessing = false;
          this.prepareProcessedInvoices(this.invoiceLines);
          if (data.length > 0) {
          }
          if (this.isBankTransMode) {
            this._invoiceService
              .getMatchData(
                this._organizationService.selectedOrganization,
                this.activities[this.curIdx]['unreconciledReportId']
              )
              .subscribe((data: any) => {
                this.unreconciledData = data;
                this.prepareProcessedInvoices(this.unreconciledData);
                this.isSpinnerShown = false;
              });
          } else {
            this.isSpinnerShown = false;
          }
        });
    } else {
      this.isSpinnerShown = false;
    }
  }

  private prepareProcessedInvoices(array: any) {
    let count = 1;
    array.forEach((element) => {
      let stringToSplit = element.supplierName;

      if (stringToSplit) {
        let x = stringToSplit.split(' ');
        if (x.length == 1) {
          element.shortCode = x[0].charAt(0);
        }
        if (x.length == 2) {
          element.shortCode = x[0].charAt(0) + x[1].charAt(0);
        }
        if (x.length > 2) {
          element.shortCode = x[0].charAt(0) + x[1].charAt(0) + x[2].charAt(0);
        }
        if (x.length == 0) {
          element.shortCode = 'NA';
        }
      }
      element.visible = true;
      if (array.length > 0 && array.length == 1 && !this.isMatchSelected) {
        element.selected = true;
        this.changeSelectedMatch(false, element.id);
      } else {
        element.selected = false;
      }
      element.rowCount = count;
      count = count + 1;
      if (count == 7) {
        count = 1;
      }
    });
  }

  changeTransaction(type: string) {
    if (type == 'prev' && this.curIdx == 0) {
      return;
    } else if (type == 'next' && this.curIdx == this.activities.length - 1) {
      return;
    }

    this.isNoteExpanded = false;
    this.recordId = '';
    this.isSpinnerShown = true;
    setTimeout(() => {
      if (type == 'prev') {
        this.curIdx = this.curIdx == 0 ? 0 : this.curIdx - 1;
        this.recordId = this.activities[this.curIdx]['unreconciledReportId'];
      } else if (type == 'next') {
        this.curIdx =
          this.curIdx == this.activities.length - 1
            ? this.curIdx
            : this.curIdx + 1;
        this.recordId = this.activities[this.curIdx]['unreconciledReportId'];
      }
      this.isNoteExpanded = true;
      this.getRelatedData();
    });
  }

  setNoteType() {
    this.recordId = this.invoiceId;
    this.noteType = 'Invoice';
    if (this.router.url.toLowerCase().indexOf('suppliers') > 0) {
      this.noteType = 'Contact';
      this.recordId = this.supplierId;
    }
    if (this.isBankTransMode) {
      this.noteType = 'unmatched';
      //this.recordId='15e3f792-64ba-4b10-96a7-288651214740';
    }
  }
  moveBackClick() {
    this.moveBack.emit();
  }

  datePipe: DatePipe = new DatePipe(this.getNavigatorLanguage());
  getFormattedDate(date: Date) {
    var createdDate = this.datePipe.transform(
      date + 'Z',
      'dd/MM/yy',
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      this.getNavigatorLanguage()
    );
    var todaydate = new Date();
    var currentDate = this.datePipe.transform(todaydate, 'dd/MM/yy');
    if (createdDate == currentDate) {
      return (
        'Today at ' +
        this.datePipe.transform(
          date + 'Z',
          'h:mm a',
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          this.getNavigatorLanguage()
        )
      );
    } else {
      return (
        createdDate +
        ' at ' +
        this.datePipe.transform(
          date + 'Z',
          'h:mm a',
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          this.getNavigatorLanguage()
        )
      );
    }
  }

  getNavigatorLanguage() {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return 'en-US';
    }
  }

  removeTrans() {
    this.confirmationDialogService
      .confirm(
        'Confirm',
        'Do you want to delete this transaction?',
        'Yes',
        'No',
        ''
      )
      .then((confirm) => {
        if (confirm) {
          let data = { Action: 'removetrans' };
          this.saveTransactionStatus(data);
        } else {
          // this.DeleteSavedReport();
        }
      })
      .catch(() =>
        console.log(
          'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
        )
      );
  }
  saveTransactionStatus(data) {
    (data.AssignedBy = sessionStorage.Id),
      (data.UnreconciledReportId =
        this.activities[this.curIdx]['unreconciledReportId']),
      this._organizationService
        .saveTransactionStatus(data)
        .subscribe((result: any) => {
          if (result.status == 1) {
            if (data.Action == 'removetrans') {
              this.deleteTrans.emit(
                this.activities[this.curIdx]['unreconciledReportId']
              );
            }
            this.activities.splice(this.curIdx);
            this.curIdx =
              this.activities.length > this.curIdx || this.curIdx == 0
                ? this.curIdx
                : this.curIdx - 1;
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Success,
              'Success',
              result.Message
            );
          } else {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              result.Message
            );
          }
        });
  }
  assignTrans(obj: any) {
    this.assignTransa.emit(obj);
  }
  transNote(obj: any) {
    this.transNotes.emit(obj);
  }

  fileObj: any;
  uploadFile(file: any) {
    this.fileObj = file;
    this.activities[this.curIdx]['relatedID'] = file.id;
    this.activities[this.curIdx]['relatedName'] = file.name;
  }

  added(event: Event) {
    // this.loading = true;
    const [uploadedFile] = Array.from(
      (event.target as HTMLInputElement).files as FileList
    );

    let fileObj = { name: uploadedFile.name, file: uploadedFile };
    this.relatedName = fileObj.file.name;
    this.fileObj = fileObj;
    this.relatedID = null;
    this.isFileUploadProcessing = true;
    this.sendFile();
  }
  relatedID: string = null;
  relatedName: string = 'Test';
  uploadedFile: any;
  isFileProcessing: boolean = false;
  dropped(uploadedFiles: NgxFileDropEntry[]) {
    const fileEntry = uploadedFiles[0].fileEntry.isFile
      ? (uploadedFiles[0].fileEntry as FileSystemFileEntry)
      : null;
    let fileObj: any;
    fileEntry?.file((files: File) => {
      fileObj = { name: files.name, file: files };
      this.fileObj = fileObj;
      this.relatedName = fileObj.file.name;
      this.isFileUploadProcessing = true;
      this.relatedID = null;
      this.sendFile();
    });
  }

  async sendFile() {
    this.isFileProcessing = true;
    this.isFileUploadProcessing = true;
    // setTimeout(() => {
    //   this.isFileProcessing=false;
    // }, 5000);
    await this._invoiceService.uploadInvoice({
      organisationId: this._organizationService.selectedOrganization,
      invoice: this.fileObj.file,
      recordId: this.recordId,
      isBankTransMode: this.isBankTransMode,
    });

    this._recognizedInvoiceService.invoiceState$.pipe(take(1)).subscribe(
      (invoice: any) => {
        this.relatedID = invoice.id;
        this.relatedName = this.fileObj.file.name;
        this.uploadFile({ name: this.fileObj.file.name, id: invoice.id });
        this.isFileUploadProcessing = false;
        var model = {
          unreconciledReportIds: this.invoiceId,
          invoiceId: this.relatedID,
          organizationId: this._organizationService.selectedOrganization,
        };
        this._invoiceService
          .updateSuggestedMatches(model)
          .pipe(take(1))
          .subscribe(() => {
            this.getRelatedData();
            this.refreshDataOnMatch.emit();
            // this.activities[this.curIdx]['status'] = "Completed";
          });
      },
      (e: Error) => {
        this._toasterMessageService.displayToasterState(
          ToasterTypes.Error,
          'Error',
          e.message
        );
        // this.isFileProcessing=false;
      }
    );
  }
  changeSelectedMatch(event, id) {
    if (!event) {
      this.reconcileData = new InvoiceUnReconcileShort();
      this.unreconciledData.forEach((num, index) => {
        this.unreconciledData[index].visible = false;
        this.unreconciledData[index].selected = false;
      });
      if (this.unreconciledData.findIndex((x) => x.id == id) > -1) {
        this.unreconciledData.find((x) => x.id == id).visible = true;
        this.unreconciledData.find((x) => x.id == id).selected = !event;
        this.isMatchSelected = true;
        this.reconcileData = this.unreconciledData.find((x) => x.id == id);
      }
    } else {
      this.reconcileData = new InvoiceUnReconcileShort();
      this.unreconciledData.forEach((num, index) => {
        this.unreconciledData[index].visible = true;
        this.unreconciledData[index].selected = false;
      });
      this.isMatchSelected = false;
    }
  }

  matchTransaction() {
    this.matchProcessing = true;
    var model = {
      unreconciledReportIds: this.reconcileData.unreconciledReportId,
      invoiceId: this.reconcileData.id,
      organizationId: this._organizationService.selectedOrganization,
    };
    this._invoiceService
      .updateSuggestedMatches(model)
      .pipe(take(1))
      .subscribe(
        () => {
          if (
            !this.reconcileData.isProcessed &&
            this.reconcileData.required_Fields
          ) {
            this._getInvoice(this.reconcileData.id);
          } else {
            this.getRelatedDataMatch();
          }
          this.refreshDataOnMatch.emit();
          // this.activities[this.curIdx]['status'] = "Completed";
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(
            ToasterTypes.ErrorInbox,
            '',
            `Error!`
          );
        }
      );
  }

  private _getInvoice(id) {
    if (id) {
      this._invoiceService
        .getInvoiceById(id)
        .pipe(take(1))
        .subscribe(
          (invoice: Invoice) => {
            this.invoice = invoice;
            this.handleInvoiceType();
            this.publishInvoice();
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    }
  }

  private handleInvoiceType() {
    if (this.invoice.type !== null) {
      let invoiceType = this.invoice.type.toString();
      if (invoiceType == 'Credit Note' || invoiceType == 'ACCPAYCREDIT') {
        this.type = 'ACCPAYCREDIT';
      } else if (invoiceType == 'Supplier Invoice' || invoiceType == 'ACCPAY') {
        this.type = 'ACCPAY';
      } else if (invoiceType == 'Expense Claim' || invoiceType == 'EXPCLAIMS') {
        this.type = 'EXPCLAIMS';
      } else if (invoiceType == 'Receipt' || invoiceType == 'EXP') {
        this.type = 'EXP';
      }
    } else {
      this.type = 'ACCPAY';
    }
  }

  publishInvoice() {
    if (this.type == 'ACCPAYCREDIT') {
      this._invoiceService
        .publishCreditNote(this.getCreditNoteObject())
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            this.getRelatedDataMatch();
          },
          (e: Error) => {}
        );
    } else if (this.type == 'EXP') {
      this.publishReceipt();
    } else {
      this._invoiceService
        .publishInvoice(this.getInvoiceObject())
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            this.getRelatedDataMatch();
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    }
  }

  getRelatedDataMatch() {
    this._invoiceService
      .getRelatedData(
        this._organizationService.selectedOrganization,
        this.reconcileData.id.toString()
      )
      .subscribe((data: any) => {
        this.invoiceLines = data;
        if (data.length > 0) {
          this.activities[this.curIdx]['relatedID'] = this.reconcileData.id;
        }
        this.prepareProcessedInvoices(this.invoiceLines);
        this.isMatchSelected = false;
        this.matchProcessing = false;
        this.isSpinnerShown = false;
      });
  }

  publishReceipt() {
    if (this.IsQuickBook) {
      this._invoiceService
        .publishReceiptQBO(this.getReceiptObjectQBO())
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            this.getRelatedDataMatch();
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    } else {
      this._invoiceService
        .publishReceipt(this.getReceiptObject())
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            this.getRelatedDataMatch();
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    }
  }

  getInvoiceObject(isSaving: boolean = false): CreatedInvoiceWithLines {
    let invoice = new CreatedInvoiceWithLines();
    invoice.amountDue = this.invoice.amountDue ?? 0;
    invoice.currency = this.invoice.currency;
    invoice.date = this.invoice.date;
    invoice.dueDate = isSaving
      ? this.invoice.dueDate
      : !this.invoice.dueDate
      ? this.invoice.date
      : this.invoice.dueDate;
    invoice.supplierName = this.invoice.supplierName;
    invoice.supplierId = this.invoice.supplierId;
    (invoice.id = this.invoice.id.toString() ?? ''),
      (invoice.invoiceNumber = this.invoice.invoiceNumber);
    invoice.organisationId =
      this.invoice.organisationId ??
      this._organizationService.selectedOrganization;
    invoice.paymentStatus = this.invoice.paymentStatus;
    invoice.pdfUrl = this.invoice.pdfUrl;
    invoice.subTotal = this.invoice.subTotal ?? 0;
    invoice.totalTax = this.invoice.totalTax ?? 0;
    invoice.type = this.invoice.type;
    invoice.invoiceLines = this.invoice.invoiceLines;
    invoice.InvoiceOrCreditNote = this.invoice.type;
    invoice.paidBy = this.invoice.paidBy;
    invoice.expenseReportId = this.invoice.expenseReportId;
    invoice.expenseReportName = this.invoice.expenseReportName;
    invoice.paymentAccountNumber = this.invoice.paymentAccountNumber;
    invoice.paymentDate = this.invoice.paymentDate;
    invoice.unreconciledReportIds = this.invoiceId;
    if (invoice.invoiceLines?.length > 0) {
      for (let line of invoice.invoiceLines) {
        line.organisationId =
          this.invoice.organisationId ??
          this._organizationService.selectedOrganization;
        if (line.totalTax == null) {
          line.totalTax = 0;
        }
      }
    }

    return invoice;
  }

  getCreditNoteObject(isSaving: boolean = false): CreditNoteDTO {
    let creditNote = new CreditNoteDTO(
      this._organizationService.selectedOrganization
    );
    Object.keys(creditNote).reduce(
      (a, k) => ((a[k] = this.creditNote[k]), a),
      creditNote
    );
    creditNote.contact = {
      contactID: this.invoice.supplierId,
      name: this.invoice.supplierName,
      status: 'ACTIVE',
    };
    creditNote.invoiceID = this.invoice.id;
    creditNote.date = this.invoice.date;
    creditNote.subTotal = this.invoice.subTotal;
    creditNote.totalTax = this.invoice.totalTax;
    creditNote.total = this.invoice.amountDue;
    creditNote.lineItems = this.lineItemList;
    creditNote.PdfUrl = this.invoice.pdfUrl;
    creditNote.creditNoteID = this.invoice.id.toString();
    creditNote.InvoiceOrCreditNote = 'Credit Note';
    creditNote.currency = this.invoice.currency;
    creditNote.currencyCode = this.invoice.currency;
    creditNote.invoiceNumber = this.invoice.invoiceNumber;
    creditNote.unreconciledReportIds = this.invoiceId;
    return creditNote;
  }

  getReceiptObject(isSaving: boolean = false): BankTransactionDTO {
    let bankTransaction = new BankTransactionDTO(
      this._organizationService.selectedOrganization
    );
    Object.keys(bankTransaction).reduce(
      (a, k) => ((a[k] = this.bankTransation[k]), a),
      bankTransaction
    );

    let currentPaymentFrom: any = this.bankAccountList.find(
      (x) => x.accountID == this.activities[this.curIdx]['accountID']
    );
    bankTransaction.bankAccount = {
      accountID: currentPaymentFrom?.accountID ?? this.invoice?.accountID,
      name: currentPaymentFrom?.name ?? this.invoice?.accountName,
      type:
        currentPaymentFrom?.type == 'CREDITCARD'
          ? 'BANK'
          : currentPaymentFrom?.type,
      currencyCode: this.invoice.currency,
      bankAccountNumber: currentPaymentFrom?.bankAccountNumber,
    };
    bankTransaction.contact = {
      contactID: this.invoice.supplierId,
      name: this.invoice.supplierName,
      status: 'ACTIVE',
    };
    bankTransaction.date = this.invoice.date;
    bankTransaction.subTotal = this.invoice.subTotal;
    bankTransaction.totalTax = this.invoice.totalTax;
    bankTransaction.total = this.invoice.amountDue;
    bankTransaction.PdfUrl = this.invoice.pdfUrl;
    bankTransaction.invoiceId = this.invoice.id.toString();
    bankTransaction.InvoiceOrCreditNote = 'Receipt';
    bankTransaction.type = DocumentType['Receipt'];
    bankTransaction.currency = this.invoice.currency;
    bankTransaction.currencyCode = this.invoice.currency;
    bankTransaction.invoiceNumber = this.invoice.invoiceNumber;
    bankTransaction.paymentAccountNumber = this.invoice.paymentAccountNumber;
    bankTransaction.paymentStatus = this.invoice.paymentStatus;
    bankTransaction.lineItems = this.invoice.invoiceLines;
    bankTransaction.unreconciledReportIds = this.invoiceId;
    if (bankTransaction.lineItems?.length > 0) {
      for (let line of bankTransaction.lineItems) {
        line.accountName = '';
        line.organisationId =
          this.invoice.organisationId ??
          this._organizationService.selectedOrganization;
        if (line.totalTax == null) {
          line.totalTax = 0;
        }
      }
    }
    return bankTransaction;
  }

  getReceiptObjectQBO(isSaving: boolean = false): CreatedInvoiceWithLines {
    let invoice = new CreatedInvoiceWithLines();
    invoice.amountDue = this.invoice.amountDue ?? 0;
    invoice.currency = this.invoice.currency;
    invoice.date = this.invoice.date;
    invoice.dueDate = isSaving
      ? this.invoice.dueDate
      : !this.invoice.dueDate
      ? this.invoice.date
      : this.invoice.dueDate;
    invoice.supplierName = this.invoice.supplierName;
    invoice.supplierId = this.invoice.supplierId;
    (invoice.id = this.invoice.id.toString() ?? ''),
      (invoice.invoiceNumber = this.invoice.invoiceNumber);
    invoice.organisationId =
      this.invoice.organisationId ??
      this._organizationService.selectedOrganization;
    invoice.paymentStatus = this.invoice.paymentStatus;
    invoice.pdfUrl = this.invoice.pdfUrl;
    invoice.subTotal = this.invoice.subTotal ?? 0;
    invoice.totalTax = this.invoice.totalTax ?? 0;
    invoice.type = this.invoice.type;
    invoice.invoiceLines = this.invoice.invoiceLines;
    invoice.InvoiceOrCreditNote = this.invoice.type;
    invoice.paidBy = this.invoice.paidBy;
    invoice.expenseReportId = this.invoice.expenseReportId;
    invoice.expenseReportName = this.invoice.expenseReportName;
    invoice.paymentAccountNumber = this.invoice.paymentAccountNumber;
    invoice.paymentDate = this.invoice.paymentDate;
    invoice.unreconciledReportIds = this.invoiceId;
    if (invoice.invoiceLines?.length > 0) {
      for (let line of invoice.invoiceLines) {
        line.organisationId =
          this.invoice.organisationId ??
          this._organizationService.selectedOrganization;
        if (line.totalTax == null) {
          line.totalTax = 0;
        }
      }
    }

    return invoice;
  }

  GetBankAcoountDetails() {
    this._organizationService
      .GetBankAccountDetails(
        'Organisation/GetBankAccountDetails',
        this._organizationService.selectedOrganization
      )
      .subscribe(
        (response: any) => {
          if (response.status == 0) {
            this.bankAccountList = response.data;
            if (this.bankAccountList?.length > 0) {
              this.bankAccountList.forEach((payAcc) => {
                payAcc.accountID = payAcc.accountID.toLowerCase();
              });
            }
          }
        },
        (err) => {
          this.bankAccountList = [];
          this._toasterMessageService.displayToasterState(
            ToasterTypes.Error,
            'Error',
            err.message
          );
        }
      );
  }

  tabClick(tab: Pannels, isDefaultShow: boolean) {
    switch (tab) {
      case Pannels.DetailsTab:
        this.currentPannel = Pannels.DetailsTab;
        break;
      case Pannels.ActivityTab:
        this.showActivityPanel(isDefaultShow);
        break;
      default:
        this.currentPannel = Pannels.DetailsTab;
        break;
    }
  }

  showActivityPanel(isDefaultShow: boolean) {
    if (!isDefaultShow) {
      if (this.currentPannel == Pannels.ActivityTab) {
        this.currentPannel = Pannels.DetailsTab;
      } else {
        this.currentPannel = Pannels.ActivityTab;
      }
    }
  }

  addPaperwork() {
    this.isManualPaperwork = true;
    this.addDocumentModel.show();
  }

  onSelectSupplier(event: SupplierShort) {
    this.isNewSupplierLoaded = true;
    if (event == undefined) {
      this.isNewSupplier = true;
      return;
    }
    this.isNewSupplier = false;
    this.isCreateSupplierItemShown = this.isNewSupplier;
    this.selectedSupplierName = event.name;
    this.getBankAccountDetailsBySupplierId(event.id, this.selectedSupplierName);
    this.updateCurrentSupplier();
    this.selectedSupplierId = event.id;
  }

  updateCurrentSupplier() {
    this.currentSupplier.id = !!this.invoice.supplierId
      ? this.invoice.supplierId
      : this.currentSupplier?.id;
    this.currentSupplier.name = this.suppliers.find(
      (s) => s.id === this.currentSupplier.id
    )?.name;
    this.currentSupplier.alias = !this.currentSupplier.alias
      ? this.invoice.supplierName
      : this.currentSupplier.alias;
    // this.hexColorClass = this.suppliers.find(s => s.id === this.currentSupplier.id)?.hexColorClass;
  }

  getBankAccountDetailsBySupplierId(supplierId: string, supplieName: string) {
    this._bankAccountService
      .getBankAccountBySupplierId(
        supplierId,
        this._organizationService.selectedOrganization
      )
      .pipe(take(1))
      .subscribe((data) => {
        if (!!data) {
          Object.keys(data).forEach(
            (k) =>
              (this.bankAccount[k] = !!data[k] ? data[k] : this.bankAccount[k])
          );
          this.bankAccount.contactId = supplierId;
          this.bankAccount.accountName = supplieName;
        } else {
        }
      });
  }

  handleSupplierName(event) {
    this.newSupplier.name = event;
    const matchedSupplier = this.suppliers?.filter(
      (s) => s.name == this.newSupplier.name
    );

    if (matchedSupplier?.length == 0 && event) {
      this.isCreateSupplierItemShown = true;
    } else if (!event) {
      this.handleSupplierBlur();
    } else {
      this.isCreateSupplierItemShown = false;
    }
  }

  handleSupplierBlur() {
    this.newSupplier.name = this.invoice.supplierName;
    this.isCreateSupplierItemShown = this.isNewSupplier;
  }

  createNewSupplier() {
    if (!this.creatingNewSupplier) {
      this.creatingNewSupplier = true;
      this.addNewSupplier(this.newSupplier, false);
    }
  }

  addNewSupplier(
    supplier: NewSupplier,
    setInvoiceSupplierName: boolean = true
  ) {
    supplier.bankAccountRequestModel = null;
    supplier.name = setInvoiceSupplierName
      ? this.invoice.supplierName
      : supplier.name;
    const name = JSON.parse(JSON.stringify(supplier.name));
    const newSupplier = JSON.parse(JSON.stringify(supplier));
    newSupplier.bsb = newSupplier.bsb?.replace('-', '');
    newSupplier.currency = this.defaultCurrency;
    newSupplier.IsSupplier = true;
    newSupplier.OrganizationId = this._organizationService.selectedOrganization;
    newSupplier.hexColorClass = 'sup_img_box_' + this.invoiceColorNumber;
    if (!newSupplier.defaultAccount.id) newSupplier.defaultAccount = null;
    this.creatingNewSupplier = true;
    this._supplierService
      .createSupplier(newSupplier)
      .pipe(this.untilThis)
      .subscribe((result) => {
        if (result?.supplierId == 'ConnectionLost') {
          if (this.IsQuickBook) {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.ErrorWithHtml,
              null,
              `QuickBooks connection lost. Please <a  class=\"toastr-link\" href=\"${QuickBookConnection.QuickBookOauth2Link}\">refresh</a> connection`
            );
          } else {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.ErrorWithHtml,
              null,
              `Xero connection lost. Please <a  class=\"toastr-link\" href=\"${xeroParamsForConnection.xeroOauth2Link}\">refresh</a> connection`
            );
          }
        } else if (result?.message !== undefined) {
          this._toasterMessageService.displayToasterState(
            ToasterTypes.Error,
            null,
            result?.message
          );
          this.isNewSupplier = false;
          this.creatingNewSupplier = false;
          this.isCreateSupplierItemShown = false;
          const refField = document.getElementById('refField');
          refField.focus();
        } else {
          this.invoice.supplierId = result.supplierId;
          this.suppliers = [
            ...this.suppliers,
            {
              id: result.supplierId,
              name: supplier.name,
              defaultAccountCode: supplier?.defaultAccount.code,
            },
          ];
          this.isNewSupplier = false;
          this.creatingNewSupplier = false;
          this.isCreateSupplierItemShown = false;
          const refField = document.getElementById('refField');
          this.invoice.supplierName = name;
          refField.focus();
          this.account = supplier?.defaultAccount;
          if (supplier?.defaultAccount?.name == '') {
            supplier.defaultAccount.name = null;
          }
          this.getBankAccountDetailsBySupplierId(
            this.invoice.supplierId,
            this.invoice.supplierName
          );
          this.updateCurrentSupplier();
          this.updateSupplierStatus();
          $('.ng-dropdown-panel').remove();
          $('#ddlsupplier').removeClass('ng-select-opened');
        }
      });
  }

  updateSupplierStatus() {
    if (this.invoice.supplierName != null && this.invoice.supplierName != '') {
      const supplier = tryGetObjectByProperty(
        this.suppliers,
        'name',
        this.invoice.supplierName
      ) as SupplierShort;
      this.isNewSupplierLoaded = supplier ? true : false;
      this.isNewSupplier = !supplier && !this.invoice.supplierId ? true : false;
      this.isCreateSupplierItemShown = this.isNewSupplier;
      this.newSupplier.name = this.invoice.supplierName;
      this.invoice.supplierId = supplier?.id ?? this.invoice.supplierId;
    }
  }

  getAccounts() {
    this._bankAccountService
      .getPaymentAccounts(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe((accounts: PaymentAccount[]) => {
        this.accounts = accounts;
        !!this.invoice?.invoiceLines[0]?.accountId
          ? (this.account = this.accounts.find(
              (a) =>
                a.id.toLowerCase() ===
                this.invoice.invoiceLines[0].accountId.toLowerCase()
            ))
          : this.updateAccount();
      });
  }

  updateAccount() {
    const code = this.suppliers.find(
      (s) => s.id === this.invoice.supplierId
    )?.defaultAccountCode;
    let accountFind = this.accounts.find((x) => x.code == code);
    if (accountFind) {
      this.account = accountFind as PaymentAccount;
    } else {
      this.account = new PaymentAccount();
    }

    if (this.account?.name == '') {
      this.account.name = null;
    }
  }

  getSuppliers() {
    this._supplierService
      .getSuppliers(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe((suppliers: SupplierShort[]) => {
        this.suppliers =
          suppliers?.sort((a, b) => a.name.localeCompare(b.name)) || [];
        this.suppliers.forEach((x) => (x.name = this.stripHTML(x.name)));
        this.supplierSearchList = this.suppliers;
      });
  }

  onSelectAccount(event: PaymentAccount) {
    if (event == undefined) {
      let data = {
        code: null,
        id: null,
        name: null,
        accountID: null,
      };
      this.account = data;
    }
    let data = {
      code: event.code,
      id: event.id,
      name: event.name,
      accountID: event.id,
    };
    this.account = data;
  }

  stripHTML(HTMLText: string): string {
    const reg = /<[^>]+>/gi;
    return HTMLText.replace(reg, '');
  }

  generateRandomNumber(): number {
    return Math.floor(100000 + Math.random() * 900000);
  }

  closeModel() {
    this.isManualPaperwork = false;
    this.addDocumentModel.hide();
  }

  addDocumentDetails() {
    this.addDocumentForm.markAllAsTouched();

    // stop here if form is invalid
    if (this.addDocumentForm.invalid) {
      return;
    }
    this.updateCurrentMatchData();
    this.isManualSaved = true;
    this.addDocumentModel.hide();
  }

  updateCurrentMatchData() {
    let currentPaymentFrom: any = this.bankAccountList.find(
      (x) => x.accountID == this.activities[this.curIdx]['accountID']
    );
    this.reconciledDataAdd = {
      id: Guid.create().toString(),
      invoiceNumber: 'SYNC-' + this.generateRandomNumber(),
      supplierName: this.selectedSupplierName,
      date: this.activities[this.curIdx]['transactionDate'],
      amountDue: this.activities[this.curIdx]['amount'],
      accountName: this.account ? this.account.name : '',
      unRecDescription: this.activities[this.curIdx]['description'],
      unreconciledReportId:
        this.activities[this.curIdx]['unreconciledReportId'],
      type: 'ACCPAYCREDIT',
      supplierId: this.selectedSupplierId,
      accountId: this.account.id,
      currency: this.defaultCurrency,
      taxRateId: this.organizationTaxRateId,
      taxBasis: this.orgTaxBasis,
      required_Fields: true,
      organisationId: this.organizationId,
      bankAccount: {
        accountID: currentPaymentFrom?.accountID ?? this.invoice?.accountID,
        name: currentPaymentFrom?.name ?? this.invoice?.accountName,
        type:
          currentPaymentFrom?.type == 'CREDITCARD'
            ? 'BANK'
            : currentPaymentFrom?.type,
        currencyCode: this.defaultCurrency,
        bankAccountNumber: currentPaymentFrom?.bankAccountNumber,
      },
      isProcessed: false,
      description: this.description,
    };
  }

  uploadManualInvoice() {
    this.isSaving = true;
    this._invoiceService.uploadInvoiceManually(this.reconciledDataAdd);

    this._recognizedInvoiceService.invoiceState$.pipe(take(1)).subscribe(
      (invoice: any) => {
        this.relatedID = invoice.id;
        // this.relatedName = this.fileObj.file.name;
        var model = {
          unreconciledReportIds: this.invoiceId,
          invoiceId: this.relatedID,
          organizationId: this._organizationService.selectedOrganization,
        };
        this._invoiceService
          .updateSuggestedMatches(model)
          .pipe(take(1))
          .subscribe(() => {
            this.isManualSaved = false;
            this.activities[this.curIdx]['relatedID'] = this.relatedID;
            this.getRelatedData();
            // this.activities[this.curIdx]['status'] = "Completed";
            this.isManualPaperwork = false;
            this.refreshDataOnMatch.emit();
            this.isSaving = false;
          });
      },
      (e: Error) => {
        this._toasterMessageService.displayToasterState(
          ToasterTypes.Error,
          'Error',
          e.message
        );
      }
    );
  }

  removeSuggestion(id) {
    this.isMatchSelected = false;
    var model = {
      unreconciledReportIds: this.invoiceId,
      invoiceId: id,
      organizationId: this._organizationService.selectedOrganization,
    };
    this._invoiceService
      .updateInactiveSuggestedMatches(model)
      .pipe(take(1))
      .subscribe(() => {
        this.isSpinnerShown = true;
        this._invoiceService
          .getMatchData(
            this._organizationService.selectedOrganization,
            this.activities[this.curIdx]['unreconciledReportId']
          )
          .subscribe((data: any) => {
            this.unreconciledData = data;
            this.prepareProcessedInvoices(this.unreconciledData);
            this.isSpinnerShown = false;
          });
      });
  }

  cancelUpload() {
    this.reconciledDataAdd = new InvoiceUnReconciledAdd();
    this.isManualPaperwork = false;
    this.isManualSaved = false;
    this.account = {
      id: this._organizationService.selectedOrganization,
      name: null,
      code: null,
      accountID: null,
    };
    this.addDocumentForm.reset();
  }

  closeInvoiceFormPanel() {
    this._invoiceService
    .getMatchData(
      this._organizationService.selectedOrganization,
      this.activities[this.curIdx]['unreconciledReportId']
    )
    .subscribe((data: any) => {
      this.unreconciledData = data;
      this.isMatchSelected = false;
      this.prepareProcessedInvoices(this.unreconciledData);
      this.isSpinnerShown = false;
    });
    this.showInvoiceFormPanel = !this.showInvoiceFormPanel;
  }

  showInvoiceSideBar(invoiceId, isEditable) {
    this.showInvoiceFormPanel = true;
    this.invoiceEditable = isEditable;

    if (invoiceId) {
      this._invoiceService
        .getInvoiceById(invoiceId)
        .pipe(take(1))
        .subscribe(
          (invoice: Invoice) => {
            setTimeout(() => {
              this._recognizedInvoiceService.sendExistingInvoice(invoice);
            }, 300);
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(
              ToasterTypes.Error,
              'Error',
              e.message
            );
          }
        );
    }  
  }
}
