import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apple-site',
  template: '',
})
export class AppleAppSiteAssociationComponent {
  constructor(private router: Router) {}

  ngOnInit() {
    const fileUrl = 'assets/.well-known/apple-app-site-association';
    window.location.href = fileUrl;
  }
}