import { Guid } from "guid-typescript";
import { CurrencyCode } from "../../enums/currency-code";
import { DocumentType } from "../../enums/document-type";
import { InvoicePaymentStatus } from "../../enums/payment-status";
import { InvoiceMessage } from "../invoice-message/invoice-message";
import { InvoiceLine } from "./invoice-line";
import { UnreconciledReport } from "./unreconciled-report";

export class Invoice {
  id?: Guid;
  organisationId?: string;
  referenceId?:string;
  type?: DocumentType;
  date?: Date;
  dueDate?: Date;
  legalName?: string = '';
  invoiceNumber?: string;
  supplierId?: string;
  supplierName?: string;
  amountDue?: number;
  totalTax?: number;
  subTotal?: number;
  total?:number;
  totalOutstanding ?:number;
  outstandingamount?:string;
  paymenttype?:string;
  currency?: CurrencyCode;
  pdfUrl?: string;
  description?: string;
  message?: InvoiceMessage;
  isDuplicate?: boolean;
  invoiceLines?: InvoiceLine[] = [];
  contactStatus?: string;
  accountName?:string;
  accountNumber?:string;
  paymentMethod?:string;
  expand?: Invoice[] = [];
  isInvoiceApproved? : boolean;
  approvedBy?: string;
  ApprovedDate?: Date;
  identification?: string;
  startCharApprovedBy?: string;
  startCharApprovaldBy?: string;
  bsbNumber?:string;
  hasAttachments?:boolean;
  paymentStatus?: InvoicePaymentStatus;
  pairs?: string;
  amountCredited?: number;
  amountPaid?: number;
  archived?: boolean;
  paymentDate?:Date;
  paymentId?: Guid;
  paidBy?:string;
  paidByName?:string;
  expenseReportId?: string;
  expenseReportName?: string; 
  paymentAccountNumber?: string;
  hexColorClass: string;
  error: string;
  routingNumber?: string;
  accountNumberUS?: string;
  unreconciledReportId?: string;
  transactionDate?: Date;
  accountID?: string;
  unreconciledData?: UnreconciledReport[] = [];
  required_Fields?: boolean = false;
  submissionMethod?: number = 1;
  constructor(organisationId?: string) {
    this.organisationId = organisationId;
    this.type = DocumentType["Supplier Invoice"];
    this.supplierId = null;
    this.supplierName = null;
    this.date = null;
    this.dueDate = null;
    this.invoiceNumber = '';
    this.amountDue = null;
    this.totalTax = null;
    this.subTotal = null;
    this.currency = null;
    this.description = '';
    this.message = null;
    this.contactStatus = "";
    this.accountName="";
    this.amountCredited = null;
    this.paidBy = null;
    this.expenseReportId = null;
    this.expenseReportName = "";
    this.paymentAccountNumber = null;
    this.paymentDate = null;
    this.hexColorClass = "";
    this.error = "";
    this.routingNumber = null;
    this.accountNumberUS = null;
    this.unreconciledReportId = "";
    this.transactionDate = null;
    this.accountID = "";
    this.required_Fields = false;
  }
}
