import { Guid } from "guid-typescript";
import { Tag } from "src/app/data/model/tag";
import { InvoiceLine } from "./invoice-line";
import { TaxBasis } from "../../enums/taxBasis";
import { BankTransactionAccount } from "../expense/bank-transactions";

export class InvoiceShort {
    id: Guid;
    invoiceNumber: string;
    supplierName: string;
    shortCode: string;
    date: Date;
    categories: string[];
    amountDue: number;
    rowCount: number;
    contactStatus: string;
    accountName: string;
    checked?: boolean;
    invoicePdfUrl?: string;
    tags?: Tag[];
    invoiceLines?: InvoiceLine[];
    hexColorClass?: string;
    createdAt?: Date;
    type?: string;
    dueDate?: Date;
    subtotal?: number;
    totalTax?: number;
    isProcessed?: boolean;
    visible?: boolean;
}

export class InvoiceUnReconcileShort {
    id: Guid;
    invoiceNumber: string;
    supplierName: string;
    shortCode: string;
    date: Date;
    amountDue: number;
    rowCount: number;
    contactStatus: string;
    accountName: string;
    checked?: boolean;
    hexColorClass?: string;
    type?: string;
    visible?: boolean;
    description: string;
    amount: number;
    unreconciledReportId: Guid;
    selected?: boolean;
    organizationId?: string;
    isProcessed?: boolean;
    required_Fields?: boolean;
}

export class InvoiceUnReconciledAdd {
    id: string;
    invoiceNumber: string;
    supplierName: string;
    date: Date;
    amountDue: number;
    accountName: string;
    description: string;
    unRecDescription: string;
    unreconciledReportId: string;
    type?: string;
    supplierId: string;
    accountId: string;
    currency: string;
    taxRateId: string;
    taxBasis: TaxBasis;
    required_Fields: boolean;
    organisationId: string;
    bankAccount: BankTransactionAccount;
    isProcessed?: boolean;
}
