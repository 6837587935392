import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { RegisterService } from 'src/app/data/service/register.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
@Component({
  selector: 'app-step-confirm-email',
  templateUrl: './step-confirm-email.component.html',
  styleUrls: ['./step-confirm-email.component.scss']
})
export class StepConfirmEmailComponent implements OnInit {
  constructor(
    private registerService: RegisterService,
    private router: Router,
    private _route: ActivatedRoute, 
    private _toasterMessageService: ToasterMessageService
  ) { }
  IsConfirmed: boolean;

  ngOnInit(): void {
    this.confirmMail();
  }
  loading: boolean = false;
  
  confirmMail() {
    this.registerService.confimMail("Account/ConfirmAccount?param=" + this._route.snapshot.params?.id)
    .subscribe((data: any) => {
      if(data) {
        if(data.status == 0){
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, '', data.message);
          this.router.navigate(['/signup/wizzard'], { replaceUrl: true });
        }
        else if(data.status == 1) {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, '', data.message);
        }
      }
    });
  }
}
