import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2, SecurityContext, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentType } from 'src/app/modules/bookkeeping/enums/document-type';
import { fromCamelCase } from 'src/app/core/util/camel-case-converter';
import { EnumItem } from '../../models/enum-item';
import { RecognizedInvoiceService } from '../../services/recognized-invoice.service';
import { take, takeUntil } from 'rxjs/operators';
import { Invoice } from '../../models/invoice/invoice';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { ToasterMessageService } from 'src/app/shared/toaster-message/toaster-message.service';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { NewSupplier } from '../../models/supplier/new-supplier';
import { SuppliersService } from 'src/app/data/service/suppliers.service';
import { InvoiceService } from 'src/app/data/service/invoice.service';
import { Router } from '@angular/router';
import { dateToString, stringToDate } from 'src/app/core/util/date-converter';
import { enumSelector } from 'src/app/core/util/enum-selector';
import { SupplierShort } from '../../models/supplier/supplier-short';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BankAccountService } from 'src/app/data/service/bank-account.service';
import { PaymentAccount } from '../../models/account/payment-account';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { InvoiceLine } from '../../models/invoice/invoice-line';
import { Guid } from 'guid-typescript';
import { InvoiceTotals } from '../../models/invoice/invoice-totals';
import { CreatedInvoiceWithLines } from '../../models/invoice/created-invoice-with-lines';
import { tryGetObjectByProperty } from 'src/app/core/util/get-object-by-property';
import { TaxRate } from '../../models/taxRate';
import { round } from 'src/app/core/util/round';
import { CurrencyCode } from '../../enums/currency-code';
import { TrackingCategory } from '../../models/tracking-categories.ts/trackingCategory';
import { TrackingCategoryOption } from '../../models/tracking-categories.ts/tracking-category-option';
import { SupplierWithAlias } from '../../models/supplier/supplier-with-alias';
import { BankAccount } from '../../models/account/bank-account';
import { ConfirmationDialogService } from 'src/app/shared/confirmation-dialog/confirmation-dialog.service';
import { SupplierAccountCreated } from '../../models/supplier/supplier-account-created';
import { Converter } from 'src/app/core/util/number-converter';
import { CreditNoteDTO } from '../../models/credit-note/credit-note';
import { SharedService } from '../../services/shared-service';
import { forkJoin, Subscription } from 'rxjs';
import { AccountingIntegration } from 'src/app/data/enums/accounting-integration';
import { InboxInvoicesStorageService } from '../../services/inbox-invoices-storage.service';
import { ActivatedRoute } from '@angular/router';
import { InvoicePaymentStatus } from '../../enums/payment-status';
import { UserService } from 'src/app/data/service/user.service';
import { User } from '../../models/user/user';
import { UpdatedLines } from '../../models/invoice/updated-lines';
import { BillsToPayInvoiceDetailsRequest } from '../../models/invoice/bills-to-pay-invoice-details-request';
import { ArchiveInvoice } from '../../models/invoice/archive-request';
import { xeroParamsForConnection, QuickBookConnection } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { SyncedExpClaims } from '../../models/invoice/invoice-exp-claims';
import * as $ from "jquery";

import { Pannels } from 'src/app/modules/bookkeeping/enums/sidebar-pannels';
import { sumOfArrayByProperty } from '../../../../core/util/sum-of-array-by-property';
import { TaxBasis } from '../../enums/taxBasis';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { BankTransactionDTO } from '../../models/expense/bank-transactions';
import { LineItems } from '../../models/credit-note/line-item';
import { DomSanitizer } from '@angular/platform-browser';
import { UnreconciledReport } from '../../models/invoice/unreconciled-report';

enum PaymentViewState {
  JustShowValues,
  AddPaymentAccount,
  SameValues,
  DifferentValues,
  SaveNewValues,
  HideWarning
}

@Component({
  selector: 'app-upload-invoice-form',
  templateUrl: './upload-invoice-form.component.html',
  styleUrls: ['./upload-invoice-form.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class UploadInvoiceFormComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() currentPannel: Pannels;
  @Input() isEditable: boolean = false;
  @Input() billsToPayMode: boolean = false;
  @Input() isInboxMobileMode: boolean = false;
  @Input() approvalMode: boolean = false;
  @Input() hexColorClass: string = "";
  @Output() updateDirection = new EventEmitter<void>();
  @Output() updateActionBar = new EventEmitter<void>();
  @Output() closeFormOnBillsToPayEvent = new EventEmitter<void>();
  @Output() invoiceApprovedEvent = new EventEmitter<string>();

  isActionBarShown: boolean = true;
  form!: FormGroup;
  types: EnumItem[] = enumSelector(DocumentType, fromCamelCase);
  suppliers: SupplierShort[] = [];
  supplierSearchList: SupplierShort[] = [];
  accounts: PaymentAccount[] = [];
  bankAccountList: PaymentAccount[] = [];
  reportName: string = '';
  selectedBankAccount: string;
  MyBankAccount: any;
  userDetails: any;
  userRole: number;
  listOfSelectedRecords: any = [];
  isLoaded: boolean = false;
  isNewSupplierLoaded: boolean = false;
  isSaveLoader: boolean = false;
  isNewSupplier: boolean = false;
  isAddLinesFormShown: boolean = false;
  isTotalsShown: boolean = false;
  isAliasWarningShown: boolean = false;
  isReportWarningShown: boolean = false;
  isDuplicateWarningShown: boolean = false;
  currentSupplier: SupplierWithAlias = new SupplierWithAlias();
  currentTotalTax: number;
  newSupplier: NewSupplier = new NewSupplier(this._organizationService.selectedOrganization);
  invoice: Invoice = new Invoice(this._organizationService.selectedOrganization);

  creditNote: CreditNoteDTO = new CreditNoteDTO(this._organizationService.selectedOrganization);
  bankTransation: BankTransactionDTO = new BankTransactionDTO(this._organizationService.selectedOrganization);
  account: PaymentAccount = new PaymentAccount();
  taxRates: TaxRate[] = [];
  taxRate: TaxRate;
  selectedTaxRateId: string;
  selectedExclusivTaxRateId: string;
  organizationTaxRateId: string;
  isDifferentTaxAmount: boolean = false;
  isAccountWarningShown: boolean = false;
  currencies: string[] = []; // = Object.keys(CurrencyCode);
  trackingCategories: TrackingCategory[] = [];
  selectedOptions: TrackingCategoryOption[] = [];
  selectedTrackingCategories = [null, null];
  bankAccount: BankAccount = new BankAccount(this._organizationService.selectedOrganization);
  bankName: string = '';
  defaultCurrency: CurrencyCode;
  converter = new Converter();
  type = "";
  invoiceSelectedType = "";
  lineItemList = [];
  loading: boolean = false;
  qboTaxDefault = { id: "0", name: "Ignore Tax", rate: 0, taxType: '' };
  IsQuickBook: boolean = false;
  IsNoAccounting: boolean = false;
  subscriptions: Subscription[] = [];

  showToolTipDelay: number = 400;
  isNextDisabled: boolean = false;
  isPrevioustDisabled: boolean = false;
  paymentStatusValues = [InvoicePaymentStatus.AwaitingProcessing, InvoicePaymentStatus.AwaitingPayment, InvoicePaymentStatus.Paid, InvoicePaymentStatus.Archived, InvoicePaymentStatus.Void];
  showPublishValidation: boolean = false;
  isSpinnerShown: boolean = true;
  pairs: string = '';
  isJsonKeyValueShown: boolean = false;
  user = {} as User;
  isKVPMenuItemShown: boolean = false;
  fieldWasChanged: boolean = false;
  dateLoading: boolean = false;
  dueDateLoading: boolean = false;
  paymentDateLoading: boolean = false;
  invoiceColorNumber: number = 1;
  fieldName: string = '';

  paymentViewStateValues = PaymentViewState;
  paymentViewStateValue = {} as PaymentViewState;
  showPaymentAccountFields: boolean = false;
  showUSPaymentAccountFields: boolean = false;
  warningIsReadyToBeShown: boolean = false;


  panels = Pannels;
  dateFormat: string = null;
  returnBackToText: string = null;
  addDefaultAccountLoading: boolean = false;
  supplierSearchTerm: string = null;
  creatingNewSupplier: boolean = false;
  readonlySupplierField: boolean = true;
  isCreateSupplierItemShown: boolean = false;
  isApproving: boolean = false;
  isApproveButtonShown: boolean = false;
  isExpClaim: boolean = false;
  isReceipt: boolean = false;
  users: User[] = [];
  expClaims: SyncedExpClaims[] = [];

  isNewExpReportLoaded: boolean = false;
  isNewExpReport: boolean = false;
  currentExpReport: SyncedExpClaims = new SyncedExpClaims();
  newExpReport: SyncedExpClaims = new SyncedExpClaims();
  isCreateExpReportItemShown: boolean = false;
  creatingNewExpReport: boolean = false;
  expenseReportIdInvalid: boolean = false;
  paidByInvalid: boolean = false;
  referencePlaceholder: string = "SYNC-" + (Math.floor(100000 + Math.random() * 900000)).toString();
  supplierName: string = '';
  countryCode: string = "";
  paymentStatusItem: any = [];
  selectedUnreconciledReportId: string = '';
  showMatchDialog: boolean = true;
  unreconciledReportIds: string = '';
  maindivHeight: boolean;
  showSupplierTooltip: boolean = false;
  showRefTooltip: boolean = false
  showAccountTooltip: boolean = false;
  isPageLoaded: boolean = false;
  isMatchPublish: boolean = false;
  isHorizontalDirection: boolean = false;
  isMobileDevice:boolean = false;
  isPreviewVisible: boolean = false;
  orgTaxBasis: TaxBasis = TaxBasis.DefaultRate;
  
  
  private readonly bsbPattern = /\d{3}-\d{3}/gi;
  private readonly accountNumberPattern = /^[0-9]*$/gi;
  private readonly routingPattern = /^[0-9]*$/gi;
  
  constructor(
    private _recognizedInvoiceService: RecognizedInvoiceService,
    private _organizationService: OrganizationService,
    private _suppliersService: SuppliersService,
    private _invoiceService: InvoiceService,
    private _toasterMessageService: ToasterMessageService,
    private _bankAccountService: BankAccountService,
    private _confirmationDialogService: ConfirmationDialogService,
    private _router: Router,
    private _sharedService: SharedService,
    private _inboxInvoicesStorage: InboxInvoicesStorageService,
    private _activatedRoute: ActivatedRoute,
    private _userService: UserService,
    private readonly _renderer: Renderer2,
    private _adapter: DateAdapter<any>,
    private sanitizer: DomSanitizer
  ) {
    super();
    this.dateFormat = this._organizationService.selectedOrganizationDateFormat;
    if (this.dateFormat != null) {
      this._adapter.setLocale(this.dateFormat);
    }
    this.getSuppliers();
  }

  loadPaymentStatus(value = null) {
    let paymentStatusItem: any = []
    if (value != 'EXP' && value != 'Receipt') {
      paymentStatusItem.push({
        name: 'Awaiting Payment',
        value: InvoicePaymentStatus.AwaitingProcessing
      });

    }
    if(value != 'ACCPAYCREDIT' && value != "Credit Note") {
    paymentStatusItem.push(
      {
        name: 'Paid',
        value: InvoicePaymentStatus.AwaitingPayment
      });
    }
    if (value == 'EXP' || value == 'Receipt') {
      this.isReceipt = true;
      this.invoice.paymentStatus = InvoicePaymentStatus.AwaitingPayment;
    }
    this.paymentStatusItem = paymentStatusItem;
  }
  ngOnInit() {
    this.checkIfMobileDevice();
    this.setIsQuickBookOrNot();
    this.getTaxRates();
    this.getOrganizationCurrencies();
    this.GetBankAcoountDetails();
    this.getOrganizationUsers();

    this.subscriptions.push(this._userService.loggedUserDetails
      .subscribe(data => {
        if (data) {
          this.userDetails = data;
        } else {
          this.userDetails = [];
        }
      }));
    this.subscriptions.push(this._userService.userRole$.subscribe(data => {
      this.userRole = data;
    }));
    this._organizationService.getOrganizationCountry(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.countryCode = response.countryCode;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )

    if (this.billsToPayMode) {
      this._recognizedInvoiceService.billsToPayInvoiceDetailsRequest$
        .pipe(this.untilThis)
        .subscribe(
          (requestData: BillsToPayInvoiceDetailsRequest) => {
            this.listOfSelectedRecords = requestData;
            this.isApproveButtonShown = !requestData?.isCompletedTask;
            this.returnBackToText = requestData.returnBackToText;
            //this.currentPannel = Pannels.DetailsTab;
            this.isSpinnerShown = true;
            // use forkJoin
            const invoice$ = this._invoiceService.getInvoiceDetailsForBillsToPay(requestData);
            const trackingCategories$ = this._organizationService.getOrganizationTrackingCategoriesWithOptions(this._organizationService.selectedOrganization);
            const accounts$ = this._bankAccountService.getPaymentAccounts(this._organizationService.selectedOrganization);
            const forkJoin$ = forkJoin({
              invoice: invoice$,
              categories: trackingCategories$,
              accounts: accounts$
            });

            forkJoin$.subscribe(
              (data) => {
                if (data?.invoice.bsbNumber) {
                  if (data?.invoice.bsbNumber.length == 5) {
                    data.invoice.bsbNumber = `0${data.invoice.bsbNumber}`;
                  }
                  data.invoice.bsbNumber = data.invoice.bsbNumber.slice(0, 3) + '-' + data.invoice.bsbNumber.slice(3)
                }

                this.invoiceColorNumber = requestData.invoiceColorNumber;
                this.invoice = data.invoice;
                this.bankAccount.BSBNumber = this.invoice.bsbNumber;
                this.bankAccount.accountNumber = this.invoice.accountNumber;
                this.bankAccount.routingNumber = this.invoice.routingNumber;
                this.bankAccount.accountNumberUS = this.invoice.accountNumber;
                this.newSupplier.name = this.invoice.supplierName;
                this.newSupplier.supplierId = this.invoice.supplierId;
                this.handleInvoiceType();

                this.setForm();
                this.isSpinnerShown = false;
                this.trackingCategories = data.categories;
                this.setSelectedOptions();
                this.getSelectedTaxRate();
                this.accounts = data.accounts;
                // this.hexColorClass = this.suppliers.find(s => s.id === this.invoice.supplierId.toLowerCase())?.hexColorClass;
                this.invoice.paidBy = data.invoice.paidBy != null ? data.invoice.paidBy.toLowerCase() : '';
                this.invoice.expenseReportId = this.invoice.expenseReportId != null ? data.invoice.expenseReportId.toLowerCase() : null;
                if (this.invoice.paidBy != null && this.users.length > 0 && this.users.find(p => p.id == this.invoice.paidBy) != null) {
                  this.getOrganizationExpClaims();
                }
                //if (this.invoice.expenseReportId != null) {
                //  this.invoice.expenseReportName = this.expClaims.find(p => p.id == this.invoice.expenseReportId).reportName;
                //}
                if(this.orgTaxBasis != TaxBasis.IgnoreTax) {
                  if (this.invoice.invoiceLines?.length > 0) {
                    this.account = this.accounts.find(a => a.id === this.invoice.invoiceLines[0].accountId?.toLowerCase())
                    if (!this.invoice?.invoiceLines[0]?.taxId) {
                      this.selectedTaxRateId = this.organizationTaxRateId;
                    } else {
                      this.selectedTaxRateId = this.invoice.invoiceLines[0].taxId;
                    }
                  }
                }
                else{
                  this.taxRates = [];
                  this.taxRates.push(this.qboTaxDefault);
                  this.selectedTaxRateId = '0';
                  this.organizationTaxRateId = "0";
                }

                if (this.invoice.amountCredited && this.invoice.amountCredited != 0) {
                  this.invoice.amountDue -= this.invoice.amountCredited;
                }

                if (this.invoice.amountPaid && this.invoice.amountPaid != 0) {
                  this.invoice.amountDue -= this.invoice.amountPaid;
                }

                if (!this.bankAccount.BSBNumber && !this.bankAccount.accountNumber) {
                  this.paymentViewStateValue = PaymentViewState.AddPaymentAccount;
                }

                this.isPageLoaded = true;
              },
              (e: Error) => {
                this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
              }
            );
            this.isSpinnerShown = false;
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
          }
        );
    } else {
      this.loadPaymentStatus();
      this.getTrackingCategories();
      this.setForm();
      this.getAccounts();
      if (!!this.isEditable) this.getOrganizationDefaultCurrency();
      this.subsribeToSupplierEvents();
      this.getSelectedTaxRate();
      this.subscribeToInvoiceUpdates();

      this.user = JSON.parse(this._userService.upUserDetails) as User;
      this.isKVPMenuItemShown = this.user.role == 1;
      this._inboxInvoicesStorage.getStatus(this._activatedRoute.snapshot.paramMap.get('id'));
      this._inboxInvoicesStorage.hasNextInvoices$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          this.isNextDisabled = !result;
        });

      this._inboxInvoicesStorage.hasPreviousInvoices$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          this.isPrevioustDisabled = !result;
        });

    }
    if (this.currentPannel != undefined) {
      this.tabClick(this.currentPannel, true);
    }
    else {
      this.currentPannel = Pannels.DetailsTab;
    }
    this.invoice.required_Fields = true;
  }
  searchSuppliers(event) {
    if (event) {
      this.supplierSearchList = this.suppliers.filter(s => s.name.toLowerCase().includes(event.toLowerCase()));
    } else {
      this.supplierSearchList = this.suppliers;
    }
  }

  archiveInvoice() {
    const archivedInvoice = {
      organizationId: this._organizationService.selectedOrganization,
      invoiceId: this.invoice.id.toString(),
      archivedBy: this.user.id,
      archivedAt: new Date(),
      archived: true
    } as ArchiveInvoice;

    this._invoiceService.archiveInvoice(archivedInvoice)
      .pipe(this.untilThis)
      .subscribe(
        () => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Document Archived');
          this.reroutAfterPublishing();
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  unarchiveInvoice() {
    const archivedInvoice = {
      organizationId: this._organizationService.selectedOrganization,
      invoiceId: this.invoice.id.toString(),
      archivedBy: null,
      archivedAt: null,
      archived: false
    } as ArchiveInvoice;

    this._invoiceService.unarchiveInvoice(archivedInvoice)
      .pipe(this.untilThis)
      .subscribe(
        () => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Document Unarchived');
          this._router.navigate([`/inbox/editInvoice/${archivedInvoice.invoiceId}`], { replaceUrl: true });
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  closeContactForm(invoiceId: string) {
    this.currentPannel = Pannels.DetailsTab;

    let requestData = {
      invoiceColorNumber: Math.random() * 6,
      invoiceId: invoiceId,
      organizationId: this._organizationService.selectedOrganization,
      reportId: null,
      supplierId: null
    } as BillsToPayInvoiceDetailsRequest;

    this._recognizedInvoiceService.sendBillsToPayInvoiceDetailsRequest(requestData);
  }

  closeInvoicePreviewForm(invoiceId: string) {
    this.resetDateLoadingVars();
    this.currentPannel = Pannels.DetailsTab;
    this.isPreviewVisible = false;
  }

  setIsQuickBookOrNot() {
    this.subscriptions.push(
      this._organizationService.organizationList
        .subscribe(data => {
          const org = data?.find(o => o.organisationID === this._organizationService.selectedOrganization);
          this.IsQuickBook = org?.accountingIntegration === AccountingIntegration.QBO ? true : false;
          this.IsNoAccounting = org?.accountingIntegration === AccountingIntegration.None ? true : false;
        }));
  }

  subsribeToSupplierEvents() {
    this._sharedService.isVisibleSource.subscribe((isVisible) => {
      if (isVisible) {
        this.newSupplier = {
          organizationId: this._organizationService.selectedOrganization,
          name: this.currentSupplier.name == undefined ? this.invoice.supplierName : this.currentSupplier.name,
          taxNumber: this.invoice.invoiceNumber,
          defaultAccount: new PaymentAccount(),
          firstName: '',
          lastName: '',
          email: '',
          currency: CurrencyCode.AUD,
          accountName: '',
          bsb: '',
          accountNumber: '',
          supplierId: this.currentSupplier.id
        };
        this._sharedService.sendExistingSupplier(this.newSupplier);
      }
    });
  }


  updateBankName(name: string) {
    this.bankName = name;
  }

  getTrackingCategories() {
    this._organizationService.getOrganizationTrackingCategoriesWithOptions(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (categories: TrackingCategory[]) => {
          this.trackingCategories = categories;
          this.setSelectedOptions();
        }
      )
  }

  getBankName(bsb: string) {
    this._invoiceService.getBankNameByBSB(bsb)
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.bankName = !!result.name ? result.name : '';
        }
      )
  }

  onSelectCategory(event) {
    const index = this.selectedOptions.findIndex(o => o.trackingCategoryId === event.trackingCategoryId);

    if (index !== -1) {
      this.selectedOptions[index] = event;
      this.selectedOptions[index].trackingCategoryName = this._getCategoryName(event.trackingCategoryId);
      this.saveInvoiceWithDelay(this.selectedOptions[index].trackingCategoryName);
      return;
    }
    this.selectedOptions.push(event);
    this.selectedOptions[this.selectedOptions.length - 1].trackingCategoryName = this._getCategoryName(event.trackingCategoryId);
    this.saveInvoiceWithDelay(this.selectedOptions[this.selectedOptions.length - 1].trackingCategoryName);
  }

  initInvoiceLine() {
    if (this.invoice.invoiceLines.length < 2) {
      this.invoice.invoiceLines = [new InvoiceLine(
        this.invoice.organisationId ?? this._organizationService.selectedOrganization,
        Guid.create().toString(),
        this.selectedTaxRateId,
        this.selectedOptions.map((item) => item.id),
        this.invoice?.amountDue,
        this.invoice?.totalTax,
        this.invoice?.subTotal,
        this.invoice.description,
        this.account.id === Guid.EMPTY ? null : this.account.id)
      ];
    }
  }

  initCreditNoteLine() {
    if (this.creditNote.lineItems.length < 1) {

      var item = {
        description: this.invoice?.description === undefined || this.invoice?.description === null || this.invoice?.description === '' ? this.invoice.invoiceNumber : this.invoice?.description,
        // this.selectedOptions.map((item) => item.id),
        unitAmount: this.invoice?.amountDue - (this.invoice?.totalTax === null ? 0 : this.invoice?.totalTax),
        taxType: this.selectedTaxRateId,
        taxAmount: this.invoice?.totalTax === null ? 0 : this.invoice?.totalTax,
        lineAmount: this.invoice.amountDue,
        accountCode: this.account.id === Guid.EMPTY ? null : this.account.id,
        quantity: 1
      };

      this.lineItemList.push(item);
    }
  }

  initReceiptLine() {
    if (this.invoice.invoiceLines.length < 2) {
      this.invoice.invoiceLines = [new InvoiceLine(
        this.invoice.organisationId ?? this._organizationService.selectedOrganization,
        Guid.create().toString(),
        this.selectedTaxRateId,
        this.selectedOptions.map((item) => item.id),
        this.invoice?.amountDue,
        this.invoice?.totalTax,
        this.invoice?.subTotal,
        this.invoice.description,
        this.account.id === Guid.EMPTY ? null : this.account.id)
      ];
    }
  }
  updateTotals(invoiceTotals: InvoiceTotals) {
    Object.keys(invoiceTotals).forEach(k => this.invoice[k] = invoiceTotals[k]);
    this.isTotalsShown = true;
    this.updateAmountDueFormControl(invoiceTotals.amountDue);
  }

  updateAmountDueFormControl(amountDue: number) {
    this.form.patchValue({
      amountDue: amountDue
    });
    this.form.markAsUntouched();
  }

  updateCalculatedTotals(amountDue) {
    if (amountDue < 0) {
      amountDue = Math.abs(amountDue);
      this.invoice.amountDue = amountDue;
    }
    if (amountDue) {
      if (amountDue.toString().includes('$') && amountDue.toString()?.length > 1) {
        amountDue = amountDue.replace('$', '');
      }
      if (!amountDue || amountDue == '' || amountDue == '$') {
        amountDue = 0;
      }
    }
    this.invoice.amountDue = amountDue !== null ? this.converter.stringToFloat(amountDue) : amountDue;
    this.handleTotals();
    this.checkTaxAmount();
  }

  setForm() {
    this.form = new FormGroup({
      type: new FormControl(this.invoice.type),
      date: new FormControl(this.invoice.date, [Validators.required]),
      dueDate: new FormControl(this.invoice.dueDate, [Validators.required]),
      supplier: new FormControl(this.invoice.supplierName, [Validators.required]),
      account: new FormControl(this.account?.name, [Validators.required]),
      invoiceNumber: new FormControl(this.invoice.invoiceNumber, [Validators.required, Validators.maxLength(this.IsQuickBook ? 21 : 250)]),
      selectedTaxRate: new FormControl(this.selectedTaxRateId, [Validators.required]),
      currency: new FormControl(this.invoice.currency, [Validators.required]),
      amountDue: new FormControl(this.invoice.amountDue, [Validators.required]),
      paymentStatus: new FormControl(this.invoice.paymentStatus),
      paymentDate: new FormControl(this.invoice.paymentDate),
      paymentAccountNumber: new FormControl(this.invoice.paymentAccountNumber),
      paidBy: new FormControl(this.invoice.paidBy),
      expenseReportId: new FormControl(this.invoice.expenseReportId),
    });
  }

  getAccounts() {
    this._bankAccountService.getPaymentAccounts(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (accounts: PaymentAccount[]) => {
          this.accounts = accounts;
          !!this.invoice?.invoiceLines[0]?.accountId ? this.account = this.accounts.find(a => a.id.toLowerCase() === this.invoice.invoiceLines[0].accountId.toLowerCase())
            : this.updateAccount();
        });
  }

  stripHTML(HTMLText: string): string {
    const reg = /<[^>]+>/gi;
    return HTMLText.replace(reg, "");
  }


  getSuppliers() {
    this._suppliersService.getSuppliers(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (suppliers: SupplierShort[]) => {
          this.suppliers = suppliers?.sort((a, b) => a.name.localeCompare(b.name)) || [];
          this.suppliers.forEach((x) => x.name = this.stripHTML(x.name));
          this.supplierSearchList = this.suppliers;
          if (!!this.isEditable) {
            this.updateSupplierStatus();
            this.updateCurrentSupplier();
            !!this.invoice?.invoiceLines[0]?.accountId ? this.account = this.accounts.find(a => a.id.toLowerCase() === this.invoice.invoiceLines[0].accountId.toLowerCase())
              : this.updateAccount();
            this.handleInvoiceCurrency();
          }
        });
  }

  subscribeToInvoiceUpdates() {
    this._recognizedInvoiceService.existingInvoiceState$
      .pipe(this.untilThis)
      .subscribe(
        (invoice: Invoice) => {
          if (invoice.bsbNumber) {
            if (invoice.bsbNumber.length == 5) {
              invoice.bsbNumber = `0${invoice.bsbNumber}`;
            }
            invoice.bsbNumber = invoice.bsbNumber.slice(0, 3) + '-' + invoice.bsbNumber.slice(3)
          }
          Object.keys(invoice).forEach(key => this.invoice[key] = invoice[key] !== null ? invoice[key] : this.invoice[key]);
          this.currentTotalTax = invoice.totalTax;
          this.handleInvoiceType();

          if (this.invoice.invoiceLines?.length > 0) {
            if (!this.invoice?.invoiceLines[0]?.taxId) {
              this.selectedTaxRateId = this.organizationTaxRateId;
            } else {
              this.selectedTaxRateId = this.invoice.invoiceLines[0].taxId;
            }
          }

          if(this.orgTaxBasis != TaxBasis.IgnoreTax) {
            if (this.invoice.invoiceLines?.length > 0) {
              if (!this.invoice?.invoiceLines[0]?.taxId) {
                this.selectedTaxRateId = this.organizationTaxRateId;
              } else {
                this.selectedTaxRateId = this.invoice.invoiceLines[0].taxId;
              }
            }
          }
          else{
            this.taxRates = [];
            this.taxRates.push(this.qboTaxDefault);
            this.selectedTaxRateId = '0';
            this.organizationTaxRateId = "0";
          }

          this.handleInvoiceLines();
          this.handleRecognizedDates();
          this.handleTotals();
          if (!!this.isEditable) {
            this.handleRecognizedSupplier();
            this.updateCurrentSupplier();
            this.updateAccount();
            this.checkDuplicate();
          }

          this.setValuesForOneLine();
          // this.hexColorClass = this.suppliers.find(s => s.id === this.invoice.supplierId)?.hexColorClass;
          this.supplierName = this.currentSupplier.name ? this.currentSupplier.name.split(' ')[0] : invoice.supplierName != null ? invoice.supplierName.split(' ')[0] : '';

          if (!!this.invoice.supplierId) {
            this.getBankAccountDetailsBySupplierId(this.invoice.supplierId, this.invoice.supplierName);
          } else if ((this.invoice.bsbNumber && this.invoice.accountNumber) || (this.invoice.routingNumber && this.invoice.accountNumberUS)) {
            this.bankAccount.BSBNumber = this.invoice.bsbNumber;
            this.bankAccount.accountNumber = this.invoice.accountNumber;
            this.bankAccount.branchDetails = this.invoice.bsbNumber;
            this.bankAccount.routingNumber = this.invoice.routingNumber;
            this.bankAccount.accountNumberUS = this.invoice.accountNumberUS;
            this.paymentViewStateValue = PaymentViewState.SaveNewValues;
            this.warningIsReadyToBeShown = true;
          } else {
            this.paymentViewStateValue = PaymentViewState.AddPaymentAccount;
          }

          if (this.invoice.invoiceLines?.length > 1) {
            this.updateAmountDueFormControl(invoice.amountDue);
            this.updateSelectedTaxRateFormValue(this.selectedTaxRateId);
          }

          if (this.invoice.paymentStatus) {
            this.invoice.paymentStatus = this.paymentStatusItem?.find(x => x.value == this.invoice.paymentStatus)?.value;
          } else {
            this.invoice.paymentStatus = this.paymentStatusItem?.find(x => x.value == InvoicePaymentStatus.AwaitingProcessing)?.value;
          }

          if (this.invoice.invoiceLines.length > 0) {
            if (this.invoice.invoiceLines[0]?.accountId) {
              this.account = this.accounts.find(a => a.id.toLowerCase() === this.invoice.invoiceLines[0].accountId.toLowerCase());
            } else {
              this.updateAccount();
            }
          }
         
          this.invoiceColorNumber = ((this._inboxInvoicesStorage.getInvoiceArrayIndex(this.invoice.id.toString())) % 6) + 1;
          this.isSpinnerShown = false;
          this.handleInvoiceCurrency();
          this.readonlySupplierField = this.invoice.supplierName ? true : false;

          this.isExpClaim = this.invoice.type.toString() === 'EXPCLAIMS' ? true : false;
          this.isReceipt = this.invoice.type.toString() === 'EXP' ? true : false;
          if (this.isExpClaim) {
            setTimeout(() => {
              this.handleExpClaimDetails();
            }, 400);

          }
          if (this.invoice.paymentDate == null) {
            this.invoice.paymentDate = stringToDate(invoice.transactionDate);
          }
          if (invoice.paymentAccountNumber != null) {
            this.invoice.paymentAccountNumber = invoice.paymentAccountNumber.toLowerCase();
            this.updatePaymentAccount();
          }
          if (this.invoice.unreconciledReportId != null && this.invoice.unreconciledReportId != "") {
            this.isReportWarningShown = true;
          }
          if (this.invoice.supplierName == "") {
            this.invoice.supplierName = null;
          }
          this.isPageLoaded = true;
          this.calculateDivHeight();
          this.form.valueChanges.subscribe((values) => {
            this.invoice.required_Fields = !this.form.invalid;
          });
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
          this.isSpinnerShown = false;
        }
      );
  }

  doesFileExist(urlToFile) {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    try {
      xhr.send();
      if (xhr.status == 404) {
        return false;
      } else {
        return true;
      }
    }
    catch {
      return false;
    }
  }

  handleExpClaimDetails() {
    this.invoice.dueDate = this.invoice.date;
    this.invoice.paidBy = this.invoice.paidBy?.toLowerCase();
    if (this.invoice.paidBy != null && this.users.length > 0 && this.users.find(p => p.id == this.invoice.paidBy) != null) {
      this.invoice.paidByName = this.users.find(p => p.id == this.invoice.paidBy).name;
      this.getOrganizationExpClaims();
    }
    if (this.invoice.expenseReportId != null && this.expClaims.length > 0 && this.expClaims.find(p => p.id == this.invoice.expenseReportId) != null) {
      this.invoice.expenseReportName = this.expClaims.find(p => p.id == this.invoice.expenseReportId).reportName;
    }
  }

  setValuesForOneLine() {
    if (!!this.invoice?.invoiceLines[0]?.accountId) this.account = this.accounts.find(a => a.id === this.invoice.invoiceLines[0].accountId)
    this.invoice.description = this.invoice.invoiceLines[0]?.description ?? this.invoice.description;
    this.setSelectedOptions();
  }

  setSelectedOptions() {
    for (let i = 0; i < this.invoice.invoiceLines[0]?.trackingOptions?.length; i++) {
      const category = this.trackingCategories.find(c => c.options.some(o => o.id === this.invoice.invoiceLines[0]?.trackingOptions[i]));
      this.selectedOptions[i] = category?.options.find(o => o.id === this.invoice.invoiceLines[0]?.trackingOptions[i]);
      if (this.selectedOptions[i]?.name != null && this.selectedOptions[i]?.name != "") {
        this.selectedTrackingCategories[i] = this.selectedOptions[i]?.name;
      }
    }
  }

  checkDuplicate() {
    if (!!this.invoice.isDuplicate && this.isEditable)
      this.isDuplicateWarningShown = true;
  }

  deleteInvoice() {
    this._invoiceService.deleteInvoice(this.invoice.id?.toString())
      .pipe(this.untilThis)
      .subscribe(
        () => {
          this.reroutAfterPublishing(true);
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  showDeleteInvoiceConfirmation() {
    this._confirmationDialogService.confirm('Confirm', 'Do you want to delete the invoice?', 'Yes', 'No', '')
      .then((confirm) => {
        if (confirm) {
          this.deleteInvoice();
        }
      });
  }

  getBankAccountDetailsBySupplierId(supplierId: string, supplieName: string) {
    this._bankAccountService.getBankAccountBySupplierId(supplierId, this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (data) => {
          if (!!data) {
            Object.keys(data).forEach(k => this.bankAccount[k] = !!data[k] ? data[k] : this.bankAccount[k]);
            this.bankAccount.contactId = supplierId;
            this.bankAccount.accountName = supplieName;

            if (this.countryCode == "AU") {
              this.bankAccount.BSBNumber = this.bankAccount.branchDetails
                ? this.bankAccount.branchDetails.includes('-') ? this.bankAccount.branchDetails :
                  this.bankAccount.branchDetails?.slice(0, 3) + '-' + this.bankAccount.branchDetails?.slice(3)
                : null;
              this._bankAccountService.updateBankAccount(this.bankAccount);
              this.getBankName(this.bankAccount.branchDetails?.slice(0, 3) + '-' + this.bankAccount.branchDetails?.slice(3));

              if (!this.invoice?.bsbNumber && !this.invoice.accountNumber && !this.bankAccount.BSBNumber && !this.bankAccount.accountNumber) {
                this.paymentViewStateValue = PaymentViewState.AddPaymentAccount;
              }

              // If existing record for contact in the ‘Bank Accounts’ table
              if (this.bankAccount.BSBNumber && this.bankAccount.accountNumber) {
                if (this.invoice.bsbNumber && this.invoice.accountNumber) {
                  if (this.bankAccount.BSBNumber == this.invoice.bsbNumber && this.bankAccount.accountNumber == this.invoice.accountNumber) {
                    this.paymentViewStateValue = PaymentViewState.SameValues;
                  } else {
                    this.paymentViewStateValue = PaymentViewState.DifferentValues;
                  }
                } else {
                  this.paymentViewStateValue = PaymentViewState.HideWarning;
                }
              } else { // If not an existing record for contact in the ‘Bank Accounts’ table
                if (this.invoice.bsbNumber && this.invoice.accountNumber) {
                  this.paymentViewStateValue = PaymentViewState.SaveNewValues;
                  this.bankAccount.BSBNumber = this.invoice.bsbNumber;
                  this.bankAccount.accountNumber = this.invoice.accountNumber;
                  this.bankAccount.branchDetails = this.invoice.bsbNumber;
                }
              }
            }
            else if (this.countryCode == "US") {
              this._bankAccountService.updateBankAccount(this.bankAccount);
              // this.getBankName(this.bankAccount.branchDetails?.slice(0, 3) + '-' + this.bankAccount.branchDetails?.slice(3));

              if (!this.invoice?.routingNumber && !this.invoice.accountNumberUS && !this.bankAccount.routingNumber && !this.bankAccount.accountNumberUS) {
                this.paymentViewStateValue = PaymentViewState.AddPaymentAccount;
              }

              // If existing record for contact in the ‘Bank Accounts’ table
              if (this.bankAccount.routingNumber && this.bankAccount.accountNumberUS) {
                if (this.invoice.routingNumber && this.invoice.accountNumberUS) {
                  if (this.bankAccount.routingNumber == this.invoice.routingNumber && this.bankAccount.accountNumberUS == this.invoice.accountNumberUS) {
                    this.paymentViewStateValue = PaymentViewState.SameValues;
                  } else {
                    this.paymentViewStateValue = PaymentViewState.DifferentValues;
                  }
                } else {
                  this.paymentViewStateValue = PaymentViewState.HideWarning;
                }
              } else { // If not an existing record for contact in the ‘Bank Accounts’ table
                if (this.invoice.routingNumber && this.invoice.accountNumberUS) {
                  this.paymentViewStateValue = PaymentViewState.SaveNewValues;
                  this.bankAccount.routingNumber = this.invoice.routingNumber;
                  this.bankAccount.accountNumberUS = this.invoice.accountNumberUS;
                  this.bankAccount.branchDetails = this.invoice.routingNumber;
                }
              }
            }
            this.warningIsReadyToBeShown = true;
          } else {
            if (this.countryCode == "US") {
              if (this.invoice.routingNumber && this.invoice.accountNumberUS) {
                this.bankAccount.routingNumber = this.invoice.routingNumber;
                this.bankAccount.accountNumberUS = this.invoice.accountNumberUS;
                this.bankAccount.branchDetails = this.invoice.routingNumber;
                this.paymentViewStateValue = PaymentViewState.SaveNewValues;
                this.warningIsReadyToBeShown = true;
              } else {
                this.paymentViewStateValue = PaymentViewState.AddPaymentAccount;
                this.bankAccount.accountNumberUS = "";
                this.bankAccount.routingNumber = "";
              }
            }
            else {
              if (this.invoice.bsbNumber && this.invoice.accountNumber) {
                this.bankAccount.BSBNumber = this.invoice.bsbNumber;
                this.bankAccount.accountNumber = this.invoice.accountNumber;
                this.bankAccount.branchDetails = this.invoice.bsbNumber;
                this.paymentViewStateValue = PaymentViewState.SaveNewValues;
                this.warningIsReadyToBeShown = true;
              } else {
                this.paymentViewStateValue = PaymentViewState.AddPaymentAccount;
                this.bankAccount.accountNumber = "";
                this.bankAccount.BSBNumber = "";
                this.bankAccount.branchDetails = "";
              }
            }
          }
        }
      )
  }

  updateBankAccount(account: BankAccount, countryCode: string) {
    const updatedAccount = JSON.parse(JSON.stringify(account));
    if (countryCode == "US") {
      updatedAccount.routingNumber = account.routingNumber;
      updatedAccount.branchDetails = account.routingNumber.replace('-', '');
    }
    else {
      updatedAccount.BSBNumber = account.BSBNumber;
      updatedAccount.branchDetails = account.BSBNumber.replace('-', '');
    }
    if (!updatedAccount.contactId)
      updatedAccount.contactId = this.invoice.supplierId;

    this._organizationService.UpdateBankAccount(updatedAccount)
      .pipe(take(1))
      .subscribe(
        () => {
          this.warningIsReadyToBeShown = false;
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Bank Account Updated!');
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  updateAccount() {
    const code = this.suppliers.find(s => s.id === this.invoice.supplierId)?.defaultAccountCode;
    let accountFind = this.accounts.find(x => x.code == code);
    if (accountFind) {
      this.account = accountFind as PaymentAccount;
    }
    else {
      this.account = new PaymentAccount();
    }

    if (this.account?.name == '') {
      this.account.name = null;
    }
  }

  handleTotals() {
    const rate = this.getTaxRateById(this.selectedTaxRateId);

    if (rate === 0) {
      this.invoice.subTotal = this.invoice.amountDue;
      this.invoice.invoiceLines.forEach(u=> {
        u.totalTax = 0;
      });
      this.invoice.totalTax = sumOfArrayByProperty(this.invoice.invoiceLines, 'totalTax');
      this.checkTaxAmount();
    } else if (rate > 0) {
      this.invoice.invoiceLines.forEach(u=> {
        u.amountDue = this.invoice.amountDue;
        u.totalTax = round(Math.abs(u.amountDue) - (100 * Math.abs(u.amountDue)) / (100 + rate));
      });
      if (this.invoice?.amountDue < 0) {
        this.invoice.amountDue = Math.abs(this.invoice?.amountDue);
      }
      this.invoice.totalTax = sumOfArrayByProperty(this.invoice.invoiceLines, 'totalTax');
      this.invoice.subTotal = round(this.invoice?.amountDue - this.invoice.totalTax);
      // if (this.selectedExclusivTaxRateId == null || this.selectedExclusivTaxRateId == "-1") {
      //this.invoice.subTotal = round((this.invoice?.amountDue * 100) / (100 + rate));
      // }
      this.checkTaxAmount();
    }
    else {
      this.invoice.totalTax = 0;
    }
  }

  updateTaxRate(id: string) {
    this.selectedTaxRateId = id;
    this.handleTotals();
  }

  handleRecognizedSupplier() {
    this.isLoaded = true;
    this.updateSupplierStatus();
    this.isLoaded = false;
  }

  handleSupplierName(event) {
    this.newSupplier.name = event;
    const matchedSupplier = this.suppliers?.filter(s => s.name == this.newSupplier.name);

    if (matchedSupplier?.length == 0 && event) {
      this.isCreateSupplierItemShown = true;
    } else if (!event) {
      this.handleSupplierBlur();
    } else {
      this.isCreateSupplierItemShown = false;
    }
  }

  handleSupplierBlur() {
    this.newSupplier.name = this.invoice.supplierName;
    this.isCreateSupplierItemShown = this.isNewSupplier;
  }

  createNewSupplier() {
    if (!this.creatingNewSupplier) {
      this.creatingNewSupplier = true;
      this.addNewSupplier(this.newSupplier, false);
    }
  }

  updateSupplierStatus() {
    if (this.invoice.supplierName != null && this.invoice.supplierName != "") {
      const supplier = tryGetObjectByProperty(this.suppliers, 'name', this.invoice.supplierName) as SupplierShort;
      this.isNewSupplierLoaded = supplier ? true : false;
      this.isNewSupplier = !supplier && !this.invoice.supplierId ? true : false;
      this.isCreateSupplierItemShown = this.isNewSupplier;
      this.newSupplier.name = this.invoice.supplierName;
      this.invoice.supplierId = supplier?.id ?? this.invoice.supplierId;
    }
  }

  handleRecognizedDates() {
    this.invoice.date = stringToDate(this.invoice.date);
    if (!this.invoice.dueDate) {
      this.invoice.dueDate = this.invoice.date;
    }
    else {
      this.invoice.dueDate = stringToDate(this.invoice.dueDate)
    }
  }

  onSelectSupplier(event: SupplierShort) {
    this.isLoaded = true;
    this.isNewSupplierLoaded = true;
    this.isAccountWarningShown = false;
    if (event == undefined) {
      this.isNewSupplier = true;
      return;
    }
    this.isNewSupplier = false;
    this.isCreateSupplierItemShown = this.isNewSupplier;
    this.isAliasWarningShown = !!this.currentSupplier.id ? false : true;
    this.invoice.supplierId = event.id;
    this.invoice.supplierName = event.name;
    this.getBankAccountDetailsBySupplierId(this.invoice.supplierId, this.invoice.supplierName);
    this.updateCurrentSupplier();

    const supplierCurrencyCode = Object.values(CurrencyCode).find(v => v.toString() == event.defaultCurrency);
    this.invoice.currency = supplierCurrencyCode ?? this.defaultCurrency;
    // this.updateAccount();

    this.saveInvoiceWithDelay('Supplier');
  }

  saveInvoiceWithDelay(fieldName: string, delay: number = 500) {
    setTimeout(() => {
      this.save(fieldName);
    }, delay);
  }

  onSelectAccount(event: PaymentAccount) {
    if (event == undefined) {
      let data = {
        code: null,
        id: null,
        name: null,
        accountID: null,
      }
      this.account = data;
      this.saveInvoiceWithDelay('Account');
    }
    let data = {
      code: event.code,
      id: event.id,
      name: event.name,
      accountID: event.id,
    }
    this.account = data;
    const supplier = this.suppliers?.find(s => s.id == this.currentSupplier?.id);
    this.isAccountWarningShown = this.currentSupplier?.id && !supplier?.defaultAccountCode ? true : false;
    this.saveInvoiceWithDelay('Account');
  }

  onSelectType(eventValue: string) {
    this.type = eventValue;// == "0: ACCPAY" ? "ACCPAY" : (eventValue == "1: ACCPAYCREDIT" ? "ACCPAYCREDIT" : "EXPCLAIMS");
    this.invoice.type = this.type === "ACCPAYCREDIT" ? DocumentType["Credit Note"] : this.type === "ACCPAY" ? DocumentType["Supplier Invoice"] : this.type === "EXP" ? DocumentType["Receipt"] : DocumentType["Expense Claim"];

    this.isExpClaim = false;
    this.isReceipt = false;
    if (eventValue === "EXPCLAIMS") {
      this.invoice.dueDate = this.invoice.date;
      this.isExpClaim = true;
      this.invoice.paidBy = this.userDetails.id.toLowerCase();
    }
    if (eventValue === "EXP") {
      this.invoice.dueDate = this.invoice.date;
      this.isReceipt = true;
      this.invoice.paymentStatus = this.paymentStatusItem?.find(x => x.value == InvoicePaymentStatus.Paid)?.value;
    }
    this.paymentStatusItem = [];

    this.setFormValidator(eventValue)

    this.loadPaymentStatus(eventValue);
    this.saveInvoiceWithDelay('Type');
  }

  setFormValidator(type: string) {
    if (this.form) {
      this.form.get('paymentDate').clearValidators();
      this.form.get('paymentAccountNumber').clearValidators();
      this.form.get('paidBy').clearValidators();
      this.form.get('expenseReportId').clearValidators();
      this.form.get('dueDate').clearValidators();
      if (type === "ACCPAY") {
        if (this.invoice.paymentStatus == InvoicePaymentStatus.AwaitingPayment || this.invoice.paymentStatus == InvoicePaymentStatus.Paid) {
          this.form.get('paymentDate').setValidators([
            Validators.required
          ]);
          this.form.get('paymentAccountNumber').setValidators([
            Validators.required
          ]);
        }
        this.form.get('dueDate').setValidators([
          Validators.required
        ]);

      } else if (type === "EXPCLAIMS") {
        this.form.get('paymentDate').clearValidators();
        this.form.get('paidBy').setValidators([
          Validators.required
        ]);
        this.form.get('expenseReportId').setValidators([
          Validators.required
        ]);
      } else if (type === "EXP") {
        this.form.get('paymentAccountNumber').setValidators([
          Validators.required
        ]);
      }
      this.form.get('paymentDate').updateValueAndValidity();
      this.form.get('paymentAccountNumber').updateValueAndValidity();
      this.form.get('dueDate').updateValueAndValidity();
      this.form.get('paidBy').updateValueAndValidity();
      this.form.get('expenseReportId').updateValueAndValidity();
    }
  }
  addDefaultAccount(noAlert: any) {
    this.addDefaultAccountLoading = true;
    const account = new SupplierAccountCreated(this.currentSupplier.id, this.currentSupplier.name,
      this._organizationService.selectedOrganization, this.account.code);

    this._suppliersService.addDefaultAccount(account)
      .pipe(take(1))
      .subscribe(
        () => {
          if (noAlert === false) {
            this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', "Added!");
            this.toggleAccountWarning();
            this.addDefaultAccountLoading = false;
          }
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
          this.addDefaultAccountLoading = false;
        });
  }

  updateSupplier(name: string) {
    const supplier = tryGetObjectByProperty(this.suppliers, 'name', name) as SupplierShort;
    this.isLoaded = true;
    this.isNewSupplierLoaded = true;
    if (!supplier) {
      this.isNewSupplier = true;
      this.invoice.supplierId = null;
      this.newSupplier.name = name;
    } else {
      this.isAliasWarningShown = !!this.currentSupplier.id ? false : true;
      this.isNewSupplier = false;
      this.isCreateSupplierItemShown = false;
      this.invoice.supplierId = supplier.id;
      this.updateCurrentSupplier();
    }
  }

  updateCurrentSupplier() {
    this.currentSupplier.id = !!this.invoice.supplierId ? this.invoice.supplierId : this.currentSupplier?.id;
    this.currentSupplier.name = this.suppliers.find(s => s.id === this.currentSupplier.id)?.name;
    this.currentSupplier.alias = !this.currentSupplier.alias ? this.invoice.supplierName : this.currentSupplier.alias;
    // this.hexColorClass = this.suppliers.find(s => s.id === this.currentSupplier.id)?.hexColorClass;
  }

  updatePaymentAccount() {
    this.MyBankAccount = !!this.invoice.paymentAccountNumber ? tryGetObjectByProperty(this.bankAccountList, 'accountID', this.invoice.paymentAccountNumber) as PaymentAccount : new PaymentAccount();
    if (this.MyBankAccount?.name == '') {
      this.MyBankAccount.name = null;
    }
  }

  addNewSupplier(supplier: NewSupplier, setInvoiceSupplierName: boolean = true) {
    supplier.bankAccountRequestModel = null;
    supplier.name = setInvoiceSupplierName ? this.invoice.supplierName : supplier.name;
    const name = JSON.parse(JSON.stringify(supplier.name));
    const newSupplier = JSON.parse(JSON.stringify(supplier));
    newSupplier.bsb = newSupplier.bsb?.replace('-', '');
    newSupplier.currency = this.defaultCurrency;
    newSupplier.IsSupplier = true;
    newSupplier.OrganizationId = this._organizationService.selectedOrganization;
    newSupplier.hexColorClass = 'sup_img_box_' + this.invoiceColorNumber;
    if (!newSupplier.defaultAccount.id) newSupplier.defaultAccount = null;
    this.creatingNewSupplier = true;
    this._suppliersService.createSupplier(newSupplier)
      .pipe(this.untilThis)
      .subscribe(
        (result) => {
          if (result?.supplierId == 'ConnectionLost') {
            if (this.IsQuickBook) {
              this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, null,
                `QuickBooks connection lost. Please <a  class=\"toastr-link\" href=\"${QuickBookConnection.QuickBookOauth2Link}\">refresh</a> connection`);
            } else {
              this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, null,
                `Xero connection lost. Please <a  class=\"toastr-link\" href=\"${xeroParamsForConnection.xeroOauth2Link}\">refresh</a> connection`);
            }
          }
          else if (result?.message !== undefined) {
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, null, result?.message);
            this.isNewSupplier = false;
            this.creatingNewSupplier = false;
            this.isCreateSupplierItemShown = false;
            this.isAliasWarningShown = false;
            const refField = document.getElementById('refField');
            refField.focus();
          }
          else {
            this.invoice.supplierId = result.supplierId;
            this.suppliers = [...this.suppliers, { id: result.supplierId, name: supplier.name, defaultAccountCode: supplier?.defaultAccount.code }];
            this.isNewSupplier = false;
            this.creatingNewSupplier = false;
            this.isCreateSupplierItemShown = false;
            const refField = document.getElementById('refField');
            this.invoice.supplierName = name;
            refField.focus();
            this.account = supplier?.defaultAccount;
            if (supplier?.defaultAccount?.name == '') {
              supplier.defaultAccount.name = null;
            }
            this.isAliasWarningShown = false;
            this.getBankAccountDetailsBySupplierId(this.invoice.supplierId, this.invoice.supplierName);
            this.updateCurrentSupplier();
            this.updateSupplierStatus();
            this.save('Supplier');
            $(".ng-dropdown-panel").remove();
            $("#ddlsupplier").removeClass("ng-select-opened");
          }
        }
      )
  }

  save(fieldName: string) {
    if (this.isEditable && !this.billsToPayMode) {
      this.isSaveLoader = true;
      this._invoiceService.updateInvoice(this.getInvoiceObject(true))
        .pipe(take(1))
        .subscribe(
          (response: boolean) => {
            this.invoice.required_Fields = response;
            if(!this.invoice.required_Fields) {
              this.invoice.required_Fields = this.validateForPublish();
            }
            $(".toast-success-inbox").hide();
            this._toasterMessageService.displayToasterState(ToasterTypes.SuccessInbox, "", `Updated`);
            this.isSaveLoader = false;
            this.fieldName = '';
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(ToasterTypes.ErrorInbox, '', `Error!`);
            this.fieldName = '';
          });
    }
  }

  validateForPublish(): boolean {
    if(this.isEditable){
      if (this.form.invalid) {
        return false;
      }
    }
    return true;
  }

  submit() {
    if(this.isEditable){
      let validFlag = true;
      this.updateSelectedAccountFormValue();

      if (this.form.invalid) {
        this.showPublishValidation = true;
        validFlag = false;

        const invalid = [];
        const controls = this.form.controls;
        for (const name in controls) {
          if (controls[name].invalid) {
            invalid.push(name);
          }
        }
        // Leave the line below
        return false;
      }

      this.showPublishValidation = false;

      if (validFlag) {
        if (this.isExpClaim) {
          if (this.invoice.paidBy == null) {
            this.paidByInvalid = true;
            return;
          }
          if (this.invoice.expenseReportId == null) {
            this.expenseReportIdInvalid = true;
            return;
          }
        }
        if (!this.currentSupplier?.name || this.isNewSupplier) {
          this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Warning', 'Create a supplier first, please');
        } 
        else if (this.isMatchPublish) {
          this.loading = true;
          this.matchUnreconciledData();
        }
        else {
          this.loading = true;
          this.publishInvoice();
        }
      }
    }
    else {
      this.matchUnreconciledData();
    }  
  }

  publishInvoice() {

    if (this.type == "ACCPAYCREDIT") {
      this.loading = true;
      this._invoiceService.publishCreditNote(this.getCreditNoteObject())
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            this.handlePublishResult(data);
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
            this.loading = false;
          });
    }
    else if (this.type == "EXP") {
      this.loading = true;
      this.publishReceipt();
    }
    else {
      this._invoiceService.publishInvoice(this.getInvoiceObject())
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            this.handlePublishResult(data);
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
            this.loading = false;
          });
    }
    if (!this.isNextDisabled) {
      this.isPageLoaded = false;
      this.reroutAfterPublishing();
    }
  }

  publishReceipt() {
    if (this.IsQuickBook) {
      this._invoiceService.publishReceiptQBO(this.getReceiptObjectQBO())
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            this.handlePublishResult(data);
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
            this.loading = false;
          });
    }
    else {
      this._invoiceService.publishReceipt(this.getReceiptObject())
        .pipe(take(1))
        .subscribe(
          (data: any) => {
            this.handlePublishResult(data);
          },
          (e: Error) => {
            this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
            this.loading = false;
          });
    }
  }

  private handlePublishResult(data: any) {
    if (!this.isReceipt && this.invoice.paymentStatus == 1) {
      this.invoice.referenceId = data.id;
      this.BatchPayment();
    }
    if (this.isNextDisabled) {
      this.loading = false;
      this.reroutAfterPublishing();
    }
  }

  reroutAfterPublishing(isDelete: boolean = false) {
    if (this.isNextDisabled) {
      this._router.navigate(['/inbox'], { replaceUrl: true });
    } else {
      this.clickNextArrow(isDelete);
    }
  }

  getPaymentStatusValue(item) {
    if (item != undefined || item != null) {
      return this.paymentStatusItem?.find(x => x.value == item.value)?.value;
    }
    else {
      return this.paymentStatusItem[0].value;
    }
  }

  getInvoiceObject(isSaving: boolean = false): CreatedInvoiceWithLines {
    let invoice = new CreatedInvoiceWithLines();
    this.initInvoiceLine();
    invoice.amountDue = this.invoice.amountDue ?? 0;
    invoice.currency = this.invoice.currency;
    invoice.date = dateToString(this.invoice.date);
    invoice.dueDate = isSaving ? dateToString(this.invoice.dueDate) : (!this.invoice.dueDate ? this.invoice.date : dateToString(this.invoice.dueDate));
    invoice.supplierName = this.currentSupplier.name ? this.currentSupplier.name : this.invoice.supplierName;
    invoice.supplierId = this.currentSupplier.id;
    invoice.id = this.invoice.id.toString() ?? "",
    invoice.invoiceNumber = this.invoice.invoiceNumber == "" ? null : this.invoice.invoiceNumber;
    invoice.organisationId = this.invoice.organisationId ?? this._organizationService.selectedOrganization;
    invoice.paymentStatus = this.invoice.paymentStatus;
    invoice.pdfUrl = this.invoice.pdfUrl;
    invoice.subTotal = this.invoice.subTotal ?? 0;
    invoice.totalTax = this.invoice.totalTax ?? 0;
    invoice.type = this.type === "ACCPAYCREDIT" ? DocumentType["Credit Note"] : this.type === "ACCPAY" ? DocumentType["Supplier Invoice"] : this.type === "EXP" ? DocumentType["Receipt"] : DocumentType["Expense Claim"];
    invoice.invoiceLines = this.invoice.invoiceLines;
    invoice.InvoiceOrCreditNote = this.type ?? this.invoice.type;
    invoice.paidBy = this.invoice.paidBy;
    invoice.expenseReportId = this.invoice.expenseReportId;
    invoice.expenseReportName = this.invoice.expenseReportName;
    invoice.paymentAccountNumber = this.invoice.paymentAccountNumber;
    invoice.paymentDate = this.invoice.paymentDate;
    invoice.unreconciledReportIds = this.unreconciledReportIds;
    invoice.requiredFields = this.form.valid;
    if (invoice.invoiceLines?.length > 0) {
      for (let line of invoice.invoiceLines) {
        line.organisationId = this.invoice.organisationId ?? this._organizationService.selectedOrganization;
        if (line.totalTax == null) {
          line.totalTax = 0;
        }
      }
    }

    return invoice;
  }

  getCreditNoteObject(isSaving: boolean = false): CreditNoteDTO {
    let creditNote = new CreditNoteDTO(this._organizationService.selectedOrganization);
    this.initCreditNoteLine();
    Object.keys(creditNote).reduce((a, k) => (a[k] = this.creditNote[k], a), creditNote);
    creditNote.contact = { contactID: this.currentSupplier.id, name: this.currentSupplier.name, status: "ACTIVE" };
    creditNote.invoiceID = this.invoice.id;
    creditNote.date = dateToString(this.invoice.date);
    creditNote.subTotal = this.invoice.subTotal;
    creditNote.totalTax = this.invoice.totalTax;
    creditNote.total = this.invoice.amountDue;
    creditNote.lineItems = this.lineItemList;
    creditNote.PdfUrl = this.invoice.pdfUrl;
    creditNote.creditNoteID = this.invoice.id.toString();
    creditNote.InvoiceOrCreditNote = "Credit Note";
    creditNote.currency = this.defaultCurrency;
    creditNote.currencyCode = this.defaultCurrency;
    creditNote.invoiceNumber = this.invoice.invoiceNumber;
    creditNote.unreconciledReportIds = this.unreconciledReportIds;
    return creditNote;

  }

  getReceiptObject(isSaving: boolean = false): BankTransactionDTO {
    let bankTransaction = new BankTransactionDTO(this._organizationService.selectedOrganization);
    this.initReceiptLine();
    Object.keys(bankTransaction).reduce((a, k) => (a[k] = this.bankTransation[k], a), bankTransaction);

    let currentPaymentFrom: any = this.bankAccountList.find(x => x.accountID == this.invoice.paymentAccountNumber);
    bankTransaction.bankAccount = { 
      accountID: currentPaymentFrom?.accountID, 
      name: currentPaymentFrom?.name, 
      type: currentPaymentFrom?.type == "CREDITCARD" ? "BANK" : currentPaymentFrom?.type, 
      currencyCode: this.invoice.currency, 
      bankAccountNumber: currentPaymentFrom?.bankAccountNumber 
    };
    bankTransaction.contact = { contactID: this.currentSupplier.id, name: this.currentSupplier.name, status: "ACTIVE" };
    bankTransaction.date = dateToString(this.invoice.date);
    bankTransaction.subTotal = this.invoice.subTotal;
    bankTransaction.totalTax = this.invoice.totalTax;
    bankTransaction.total = this.invoice.amountDue;
    bankTransaction.PdfUrl = this.invoice.pdfUrl;
    bankTransaction.invoiceId = this.invoice.id.toString();
    bankTransaction.InvoiceOrCreditNote = "Receipt";
    bankTransaction.type = DocumentType["Receipt"];
    bankTransaction.currency = this.defaultCurrency;
    bankTransaction.currencyCode = this.defaultCurrency;
    bankTransaction.invoiceNumber = this.invoice.invoiceNumber;
    bankTransaction.paymentAccountNumber = this.invoice.paymentAccountNumber;
    bankTransaction.paymentStatus = this.invoice.paymentStatus;
    bankTransaction.lineItems = this.invoice.invoiceLines;
    bankTransaction.unreconciledReportIds = this.unreconciledReportIds;
    if (bankTransaction.lineItems?.length > 0) {
      for (let line of bankTransaction.lineItems) {
        line.accountName = "";
        line.organisationId = this.invoice.organisationId ?? this._organizationService.selectedOrganization;
        if (line.totalTax == null) {
          line.totalTax = 0;
        }
      }
    }
    return bankTransaction;

  }

  getReceiptObjectQBO(isSaving: boolean = false): CreatedInvoiceWithLines {
    let invoice = new CreatedInvoiceWithLines();
    this.initInvoiceLine();
    invoice.amountDue = this.invoice.amountDue ?? 0;
    invoice.currency = this.invoice.currency;
    invoice.date = dateToString(this.invoice.date);
    invoice.dueDate = isSaving ? dateToString(this.invoice.dueDate) : (!this.invoice.dueDate ? this.invoice.date : dateToString(this.invoice.dueDate));
    invoice.supplierName = this.currentSupplier.name ? this.currentSupplier.name : this.invoice.supplierName;
    invoice.supplierId = this.currentSupplier.id;
    invoice.id = this.invoice.id.toString() ?? "",
      invoice.invoiceNumber = this.invoice.invoiceNumber;
    invoice.organisationId = this.invoice.organisationId ?? this._organizationService.selectedOrganization;
    invoice.paymentStatus = this.invoice.paymentStatus;
    invoice.pdfUrl = this.invoice.pdfUrl;
    invoice.subTotal = this.invoice.subTotal ?? 0;
    invoice.totalTax = this.invoice.totalTax ?? 0;
    invoice.type = this.type === "ACCPAYCREDIT" ? DocumentType["Credit Note"] : this.type === "ACCPAY" ? DocumentType["Supplier Invoice"] : this.type === "EXP" ? DocumentType["Receipt"] : DocumentType["Expense Claim"];
    invoice.invoiceLines = this.invoice.invoiceLines;
    invoice.InvoiceOrCreditNote = this.type ?? this.invoice.type;
    invoice.paidBy = this.invoice.paidBy;
    invoice.expenseReportId = this.invoice.expenseReportId;
    invoice.expenseReportName = this.invoice.expenseReportName;
    invoice.paymentAccountNumber = this.invoice.paymentAccountNumber;
    invoice.paymentDate = this.invoice.paymentDate;
    invoice.unreconciledReportIds = this.unreconciledReportIds;
    if (invoice.invoiceLines?.length > 0) {
      for (let line of invoice.invoiceLines) {
        line.organisationId = this.invoice.organisationId ?? this._organizationService.selectedOrganization;
        if (line.totalTax == null) {
          line.totalTax = 0;
        }
      }
    }

    return invoice;
  }

  updateInvoiceLines(updatedLines: UpdatedLines) {
    this.updateTotals(updatedLines.invoiceTotals);
    this.invoice.invoiceLines = updatedLines.invoiceLines;

    if (updatedLines?.invoiceLines?.length > 0) {
      this.account = this.accounts.find(acc => acc.id == this.invoice.invoiceLines[0]?.accountId);
      this.updateSelectedAccountFormValue();
    }

    this.selectedTaxRateId = this.invoice.invoiceLines[0]?.taxId;
    this.updateSelectedTaxRateFormValue(this.selectedTaxRateId);

    if (this.invoice.invoiceLines?.length === 1)
      this.setValuesForOneLine();
    this.saveInvoiceWithDelay('Invoice lines');
  }

  updateSelectedAccountFormValue() {
    this.form.patchValue({
      account: this.account.name
    });
    this.form.markAsUntouched();
  }

  updateSelectedTaxRateFormValue(selectedTaxRateId: string) {
    this.form.patchValue({
      selectedTaxRate: selectedTaxRateId
    });
    this.form.markAsUntouched();
  }

  getTaxRates() {
    this._organizationService.getTaxRates(this._organizationService.selectedOrganization)
      .pipe(this.untilThis)
      .subscribe(
        (taxRates: TaxRate[]) => {
          // if (this.IsQuickBook || this.IsNoAccounting) {
          //   taxRates.push(this.qboTaxDefault);
          // }
          this.taxRates = taxRates;
          this.handleTotals();
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  getOrganizationDefaultCurrency() {
    this._organizationService.getOrganizationDefaultCurrency(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.defaultCurrency = response.currency;
          this.handleInvoiceCurrency();
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  getSelectedTaxRate() {
    this._organizationService.getOrganizationTaxRateId(this._organizationService.selectedOrganization)
      .pipe(this.untilThis)
      .subscribe(
        (response) => {
          if ((this.IsQuickBook  || this.IsNoAccounting) && response.taxBasis == TaxBasis.IgnoreTax) {
            this.taxRates = [];
            this.orgTaxBasis = response.taxBasis;
            this.taxRates.push(this.qboTaxDefault);
            this.selectedTaxRateId = '0';
            this.organizationTaxRateId = "0";
          }
          else {
            if (!this.invoice?.invoiceLines[0]?.taxId) {
              this.selectedTaxRateId = response.taxRateId;
            }
            this.selectedExclusivTaxRateId = response.taxExclusiveRateId;
            this.organizationTaxRateId = response.taxRateId;
          }
          this.handleTotals();
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  addAlias() {
    this._suppliersService.saveSupplierAlias({ organizationId: this._organizationService.selectedOrganization, supplierId: this.currentSupplier.id, alias: this.currentSupplier.alias })
      .pipe(take(1))
      .subscribe(
        () => {
          this.isNewSupplier = false;
          this.toggleAliasWarning();
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  toggleCreateSupplierForm(state: boolean) {
    this.resetDateLoadingVars();

    if (this.isEditable) {
      this.newSupplier.supplierId = this.currentSupplier.id;
      this.newSupplier.name = this.currentSupplier.name ? this.currentSupplier.name : this.currentSupplier.alias;
    } else {
      this.newSupplier.supplierId = this.invoice.supplierId;
      this.newSupplier.name = this.invoice.supplierName;
    }

    if (state) {
      this.currentPannel = Pannels.ContactForm;
    } else {
      this.currentPannel = Pannels.RelatedTab;
    }
  }

  toggleAliasWarning() {
    this.isAliasWarningShown = !this.isAliasWarningShown;
    this.showSupplierTooltip = false;
  }

  toggleDuplicateWarning() {
    this.isDuplicateWarningShown = !this.isDuplicateWarningShown;
    this.showRefTooltip = false;
  }

  toggleAccountWarning() {
    this.isAccountWarningShown = !this.isAccountWarningShown;
    this.showAccountTooltip = false;
  }

  toggleTaxAmountWarning() {
    this.isDifferentTaxAmount = !this.isDifferentTaxAmount;
  }

  addLinesShow() {
    this.resetDateLoadingVars();
    this.initInvoiceLine();
    if (this.currentPannel == Pannels.SplitLinesPanel) {
      this.currentPannel = Pannels.DetailsTab;
    } else {
      this.currentPannel = Pannels.SplitLinesPanel;
    }
    this.calculateDivHeight();
  }

  showKeyValuePairs() {
    this.resetDateLoadingVars();
    this.isJsonKeyValueShown = !this.isJsonKeyValueShown;
  }

  showActivityPanel(isDefaultShow: boolean) {
    this.resetDateLoadingVars();
    if (!isDefaultShow) {
      if (this.currentPannel == Pannels.ActivityTab) {
        this.currentPannel = Pannels.DetailsTab;
      } else {
        this.currentPannel = Pannels.ActivityTab;
      }
    }
  }

  showRelatedPanel() {
    this.resetDateLoadingVars();
    if (this.currentPannel == Pannels.RelatedTab) {
      this.currentPannel = Pannels.DetailsTab;
    } else {
      this.currentPannel = Pannels.RelatedTab;
    }
  }

  clickNextArrow(isDelete: boolean = false) {
    var nextid = this._inboxInvoicesStorage.getNextInvoiceId(this.invoice.id?.toString());
    if (this.isEditable) {
      if (isDelete) {
        this._inboxInvoicesStorage.deleteInvoiceIdFromStorage(this.invoice.id?.toString());
      }
      this._router.navigateByUrl('/').then(() => {
        this._router.navigate([`/inbox/editInvoice/${nextid}`], { replaceUrl: true });
      });
    } else {
      this._router.navigateByUrl('/').then(() => {
        this._router.navigate([`/inbox/viewInvoice/${nextid}`], { replaceUrl: true });
      });
    }
  }

  clickPreviousArrow() {
    if (this.isEditable) {
      this._router.navigateByUrl('/').then(() => {
        this._router.navigate([`/inbox/editInvoice/${this._inboxInvoicesStorage.getPreviousInvoiceId(this.invoice.id?.toString())}`], { replaceUrl: true });
      });
    } else {
      this._router.navigateByUrl('/').then(() => {
        this._router.navigate([`/inbox/viewInvoice/${this._inboxInvoicesStorage.getPreviousInvoiceId(this.invoice.id?.toString())}`], { replaceUrl: true });
      });
    }
  }

  saveOnLostFocus(fieldName: string) {
    if (this.fieldWasChanged) {
      this.fieldWasChanged = !this.fieldWasChanged;
      this.save(fieldName);
    }
  }

  onDateChange() {
    if (!this.dateLoading) {
      this.dateLoading = true;
    } else {
      this.fieldName = 'Date';
      this.saveDateWhenFocusout();
    }
  }

  onDueDateChange() {
    if (!this.dueDateLoading) {
      this.dueDateLoading = true;
    } else {
      this.fieldName = 'Due Date';
      if (!this.isExpClaim) {
        this.saveDateWhenFocusout();
      }
    }
  }

  saveDateWhenFocusout() {
    setTimeout(() => {
      const btn = document.getElementById('saveButton');
      btn.focus();
      btn.click();
    }, 1000)
  }

  updateBankDetails(countryCode) {
    if (countryCode == 'US') {
      this.bankAccount.routingNumber = this.invoice.routingNumber;
      this.bankAccount.accountNumberUS = this.invoice.accountNumberUS;
    }
    else {
      this.bankAccount.BSBNumber = this.invoice.bsbNumber;
      this.bankAccount.accountNumber = this.invoice.accountNumber;
    }
    this.bankAccount.branchDetails = this.bankAccount.BSBNumber;
    this.updateBankAccount(this.bankAccount, countryCode);
  }

  saveBankDetails(countryCode) {
    if (!this.isNewSupplier) {
      this.updateBankDetails(countryCode);
    } else {
      this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Bank Account isn\'t saved', "Please, create a new supplier first");
    }
  }

  addPaymentAccount() {
    this.showPaymentAccountFields = true;
  }

  addUSPaymentAccount() {
    this.showUSPaymentAccountFields = true;
  }

  bankAccountChanged(value: string, countryCode: string) {
    if (this.isNewSupplier) {
      this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Bank Account isn\'t saved', "Please, create a new supplier first");
      return;
    }

    if (this.bankAccount?.BSBNumber && this.bankAccount?.accountNumber
      && this.bsbPattern.test(this.bankAccount?.BSBNumber) && this.accountNumberPattern.test(this.bankAccount?.accountNumber)) {
      this.updateBankAccount(this.bankAccount, countryCode);
    }
  }

  updateBSB(bsb: string) {
    this.bankAccount.BSBNumber = bsb.length === 3 ? bsb + '-' : bsb;

    if (bsb.length === 7) {
      this.getBankName(bsb);
    }
  }

  bankAccountUSChanged(value: string) {
    if (this.isNewSupplier) {
      this._toasterMessageService.displayToasterState(ToasterTypes.Warning, 'Bank Account isn\'t saved', "Please, create a new supplier first");
      return;
    }
    if (this.bankAccount?.routingNumber && this.bankAccount?.accountNumberUS
      && this.routingPattern.test(this.bankAccount?.routingNumber) && this.accountNumberPattern.test(this.bankAccount?.accountNumberUS)) {
      this.updateUSBankAccount(this.bankAccount);
    }
  }

  updateUSBankAccount(account: BankAccount) {
    const updatedAccount = JSON.parse(JSON.stringify(account));
    updatedAccount.routingNumber = account.routingNumber;
    updatedAccount.accountNumberUS = account.accountNumberUS;
    updatedAccount.contactId = this.invoice.supplierId;
    updatedAccount.accountName = this.invoice.supplierName;

    this._organizationService.UpdateBankAccount(updatedAccount)
      .pipe(take(1))
      .subscribe(
        () => {
          this.warningIsReadyToBeShown = false;
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'Bank Account Updated!');
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      )
  }

  updateRouting(routingNumber: string) {
    this.bankAccount.routingNumber = routingNumber;
  }

  tabClick(tab: Pannels, isDefaultShow: boolean) {
    switch (tab) {
      case Pannels.DetailsTab:
        this.currentPannel = Pannels.DetailsTab
        break;
      case Pannels.ActivityTab:
        this.showActivityPanel(isDefaultShow);
        break;
      case Pannels.RelatedTab:
        this.showRelatedPanel();
        break;
      default:
        this.currentPannel = Pannels.DetailsTab
        break;
    }
  }

  viewInvoice() {
    const link = this._renderer.createElement('a');
    if (this.isMobileDevice) {
      this.currentPannel = Pannels.InvoiceDetails;
      this.isPreviewVisible = true;  
    }
    else{
      link.setAttribute('target', '_blank');
      link.setAttribute('href', this._invoiceService.getDownloadInvoiceUrl(this.invoice.referenceId ?? this.invoice.pdfUrl, this._organizationService.selectedOrganization));
      link.setAttribute('download', this.invoice.referenceId ?? this.invoice.pdfUrl);
      link.click();
      link.remove();
    }
  }

  closeFormOnBillsToPay() {
    this.closeFormOnBillsToPayEvent.emit();
  }

  approveCurrentInvoice() {
    this.isApproving = true;

    const reportDataModel =
    {
      ApproveId: "",
      OrganizationId: this._organizationService.selectedOrganization,
      InvoiceId: this.invoice.referenceId ?? this.invoice.id.toString(),
      ApproveBy: localStorage.getItem('UpUserId'),
      ApprovedByName: localStorage.getItem('UserName'),
      ApprovalDate: new Date()
    }

    this._organizationService.InsertApprovals(reportDataModel)
      .pipe(this.untilThis)
      .subscribe(data => {
        if (data.status == 1) {
          this.isApproving = false;
          this._toasterMessageService.displayToasterState(ToasterTypes.Success, 'Success', 'You have approved the bill');
          this.invoiceApprovedEvent.emit(reportDataModel.InvoiceId);
        }
      },
        (e: Error) => {
          this.isApproving = false;
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        });
  }

  private resetDateLoadingVars() {
    this.dateLoading = false;
    this.dueDateLoading = false;
  }

  private _getCategoryName(id: string) {
    return this.trackingCategories.find(c => c.id === id).name;
  }

  private checkTaxAmount() {
    this.isDifferentTaxAmount = this.invoice.totalTax !== this.currentTotalTax
      && !this.taxRates.includes(this.qboTaxDefault) ? true : false;
  }

  private getTaxRateById(id: string): number | null {
    return this.taxRates.find(tr => tr.id === id)?.rate;
  }

  private handleInvoiceType() {
    this.loadPaymentStatus(this.invoice?.type);
    if (this.invoice.type !== null) {
      let invoiceType = this.invoice.type.toString();
      if (invoiceType == "Credit Note" || invoiceType == "ACCPAYCREDIT") {
        this.type = "ACCPAYCREDIT";
        this.invoiceSelectedType = "Credit Note";
        this.isExpClaim = false;
      }
      else if (invoiceType == "Supplier Invoice" || invoiceType == "ACCPAY") {
        this.type = "ACCPAY";
        this.invoiceSelectedType = "Supplier Invoice";
        this.isExpClaim = false;
      }
      else if (invoiceType == "Expense Claim" || invoiceType == "EXPCLAIMS") {
        this.type = "EXPCLAIMS";
        this.invoiceSelectedType = "Expense Claim";
        this.isExpClaim = true;
      }
      else if (invoiceType == "Receipt" || invoiceType == "EXP") {
        this.type = "EXP";
        this.invoiceSelectedType = "Receipt";
        this.isExpClaim = false;
        this.isReceipt = true;
      }
    } else {
      this.type = "ACCPAY";
      this.invoiceSelectedType = "Supplier Invoice";
    }
    this.setFormValidator(this.type);
  }

  private handleInvoiceLines() {
    if (this.invoice.invoiceLines?.length > 0) {
      for (let line of this.invoice.invoiceLines) {
        line = new InvoiceLine(
          line.organisationId,
          line.id,
          line.taxId,
          line.trackingOptions,
          line.amountDue,
          line.totalTax,
          line.subTotal,
          line.description,
          line.accountId,
          line.quantity);
      }
    }
  }

  private getOrganizationCurrencies() {
    this._organizationService.getOrganizationCurrencies(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe(
        (data: string[]) => {
          this.currencies = data;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  private handleInvoiceCurrency() {
    if (this.suppliers?.length > 0 && this.defaultCurrency && this.invoice?.id) {
      if (!this.invoice.currency) {
        const supplier = this.suppliers.find(s => s.id == this.invoice.supplierId);
        if (supplier && supplier.defaultCurrency != null && supplier.defaultCurrency != "0") {
          this.invoice.currency = Object.values(CurrencyCode).find(v => v.toString() == supplier.defaultCurrency);
        } else {
          this.invoice.currency = this.defaultCurrency;
        }
        this.bankAccount.currency = this.invoice.currency;
      }
      else {
        const supplier = this.suppliers.find(s => s.id == this.invoice.supplierId);
        if (supplier && supplier.defaultCurrency != null && supplier.defaultCurrency != "0") {
          this.invoice.currency = Object.values(CurrencyCode).find(v => v.toString() == supplier.defaultCurrency);
        } else {
          this.invoice.currency = this.defaultCurrency;
        }
        this.bankAccount.currency = this.invoice.currency;
      }
    }
    else {
      this.invoice.currency = Object.values(CurrencyCode).find(v => v.toString() == this.invoice.currency);
      if(!this.invoice.currency) {
        this.invoice.currency = this.defaultCurrency;
        this.bankAccount.currency = this.invoice.currency;
      }
    }
  }

  GetBankAcoountDetails() {
    this._organizationService.GetBankAccountDetails("Organisation/GetBankAccountDetails", this._organizationService.selectedOrganization).subscribe((response: any) => {
      if (response.status == 0) {
        this.bankAccountList = response.data;
        if (this.bankAccountList?.length > 0) {
          this.bankAccountList.forEach(payAcc => {
            payAcc.accountID = payAcc.accountID.toLowerCase();
          });
          this.selectedBankAccount = this.bankAccountList[0].accountID;
          this.updatePaymentAccount();
          if (this.bankAccountList[0]["bankName"] === "NAB") {
            //this.isNABBank = true;
          }
        }
      }
    }, err => {
      this.bankAccountList = [];
      this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.message);
    });
  }
  onSelectPaymentAccount(e) {
    this.MyBankAccount = this.bankAccountList.find(x => x.accountID === e.accountID);
    this.invoice.paymentAccountNumber = e.accountID;
    this.saveInvoiceWithDelay('PaymentAccountNumber');
  }

  savePaymentDateWhenFocusout($event) {
    if (!this.paymentDateLoading) {
      this.paymentDateLoading = true;
    } else {
      this.saveInvoiceWithDelay('PaymentDate');
      let datePipe = new DatePipe(this.dateFormat);
      this.reportName = "Payments " + datePipe.transform(this.invoice.paymentDate, this.dateFormat == 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy');
    }
  }

  // Payment Stuff Starts
  BatchPayment() {
    this.subscriptions.push(this._userService.loggedUserDetails
      .subscribe(data => {
        if (data) {
          this.userDetails = data;
        } else {
          this.userDetails = [];
        }
      }));
    this.subscriptions.push(this._userService.userRole$.subscribe(data => {
      this.userRole = data;
    }));
    let payment = [];
    let accountnumber = this.selectedBankAccount;

    if (this.IsQuickBook) {
      let paymentitem = {
        id: null,
        invoiceID: this.invoice.id,
        dueAmount: this.invoice.amountDue,
        contactID: this.currentSupplier.id,
        invoiceNumber: this.invoice.invoiceNumber,
        details: this.invoice.organisationId,
        contactName: this.currentSupplier.name,
        referenceId: this.invoice.referenceId
      }
      payment.push(paymentitem);
    }
    else if(this.IsNoAccounting) {
      let paymentitem = {
        id: null,
        invoiceID: this.invoice.referenceId,
        dueAmount: this.invoice.amountDue,
        contactID: this.currentSupplier.id,
        invoiceNumber: this.invoice.invoiceNumber,
        details: this.invoice.organisationId,
        contactName: this.currentSupplier.name,
        referenceId: this.invoice.referenceId
      }
      payment.push(paymentitem);
    }
    else {
      let paymentitem = {
        id: null,
        invoiceID: this.invoice.referenceId,
        dueAmount: this.invoice.amountDue,
        contactID: this.currentSupplier.id,
        invoiceNumber: this.invoice.invoiceNumber,
        details: this.invoice.organisationId,
        contactName: this.currentSupplier.name,
        referenceId: this.invoice.referenceId
      }
      payment.push(paymentitem);
    }

    let userId = Number(this.userDetails.userId);
    let batchPayment = {
      PaymentId: null,
      ReportName: this.reportName,
      AccountID: this.invoice.paymentAccountNumber,
      AccountName: this.MyBankAccount != null ? this.MyBankAccount.name : '',
      Paymentitem: payment,
      CreatedBy: userId,
      OrganisationId: this._organizationService.selectedOrganization,
      PaymentDate: this.invoice.paymentDate,
      PaymentAccountNumber: this.invoice.paymentAccountNumber
    }

    this._organizationService.OutstandingBatchPaymentFromInbox(this._organizationService.selectedOrganization, batchPayment)
      .pipe(take(1))
      .subscribe(
        data => {
          if (data.status == 1) {
            this._toasterMessageService.displayToasterState(ToasterTypes.SuccessWithHtml, null, "Bill " + this.invoice.invoiceNumber + "  has been processed and mark as paid");
            this.loading = false;
            this.closeContactForm(this.invoice.id.toString());
            //this.reroutAfterPublishing();
          }
          else {
            if (data.message == 'ConnectionLost') {
              if (this.IsQuickBook) {
                this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, null,
                  `QuickBooks connection lost. Please <a  class=\"toastr-link\" href=\"${QuickBookConnection.QuickBookOauth2Link}\">refresh</a> connection`);
              } else {
                this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, null,
                  `Xero connection lost. Please <a  class=\"toastr-link\" href=\"${xeroParamsForConnection.xeroOauth2Link}\">refresh</a> connection`);
              }
            } else {
              this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', data.message);
            }
          }
        },
        err => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.message);
        });
  }

  // Expense Claim Stuff goes here

  private getOrganizationUsers() {

    this._userService.getUsersByOrganizationId(this._organizationService.selectedOrganization)
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response != null) {
          this.users = response.data;
          if (this.users.length > 0) {
            if (this.invoice.paidBy != null && this.users.find(p => p.id == this.invoice.paidBy) != null) {
              this.invoice.paidByName = this.users.find(p => p.id == this.invoice.paidBy).name;
              this.getOrganizationExpClaims();
            }
            else {
              this.invoice.paidBy = null;
            }
          }
          else {
            this.invoice.paidBy = null;
          }
        }
      },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        }
      );
  }

  onSelectPaidBy(event) {
    if (event != undefined) {
      this.invoice.paidBy = event.id.toString().toLowerCase();
      this.save('Paid By');
      this.getOrganizationExpClaims();
      this.paidByInvalid = false;
    }
    else {
      this.invoice.paidBy = null;
      this.invoice.expenseReportId = null;
      this.invoice.expenseReportName = null;
      this.invoice.paidByName = null;
      this.save('Paid By');
    }
  }

  private getOrganizationExpClaims() {
    this._invoiceService.getAllExpClaimsByOrganizationId(this._organizationService.selectedOrganization, this.invoice.paidBy)
      .subscribe((response: any) => {
        if (response != null) {
          this.expClaims = response.data;
          if (this.expClaims.length > 0) {
            this.updateExpReportStatus();
          }
          else {
            this.invoice.expenseReportId = null;
          }
        }
      },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message);
        });
  }

  onSelectExpenseReport(event) {
    this.isLoaded = true;
    this.isNewExpReportLoaded = true;
    if (event == undefined) {
      this.isNewExpReport = true;
      this.save('Expense Report');
      this.expenseReportIdInvalid = false;
      return;
    }
    this.isNewExpReport = false;
    this.isCreateExpReportItemShown = this.isNewExpReport;
    this.invoice.expenseReportId = event.id.toString().toLowerCase();
    this.save('Expense Report');
    this.expenseReportIdInvalid = false;
  }

  handleExpenseReport(event) {
    if (event != undefined && event != "") {
      this.newExpReport.reportName = event;
      const matchedExpReport = this.expClaims.filter(s => s.reportName == this.newExpReport.reportName);

      if (matchedExpReport?.length == 0 && event) {
        this.isCreateExpReportItemShown = true;
      } else if (!event) {
        this.handleExpenseReportBlur();
      } else {
        this.isCreateExpReportItemShown = false;
      }
    }
    else {

    }
  }

  handleExpenseReportBlur() {
    this.newExpReport.id = this.invoice.expenseReportId;
    this.isCreateExpReportItemShown = this.isNewExpReport;
  }

  createNewExpReport() {
    if (!this.creatingNewExpReport) {
      this.creatingNewExpReport = true;
      this.addNewExpReport(this.newExpReport, false);
    }
  }

  addNewExpReport(expReport: SyncedExpClaims, setInvoiceExpReportName: boolean = true) {

    this.subscriptions.push(this._userService.loggedUserDetails
      .subscribe(data => {
        if (data) {
          this.userDetails = data;
        } else {
          this.userDetails = [];
        }
      }));
    this.subscriptions.push(this._userService.userRole$.subscribe(data => {
      this.userRole = data;
    }));
    expReport.reportName = setInvoiceExpReportName ? this.invoice.expenseReportName : expReport.reportName;
    expReport.userId = this.invoice.paidBy;
    expReport.invoiceId = this.invoice.id.toString();
    expReport.organizationId = this._organizationService.selectedOrganization;
    expReport.createdBy = this.userDetails.id;
    expReport.status = 1;
    expReport.id = "";
    this.creatingNewExpReport = true;
    this._invoiceService.createExpenseReport(JSON.parse(JSON.stringify(expReport)))
      .pipe(this.untilThis)
      .subscribe(
        (result) => {
          this.invoice.expenseReportId = result.id;
          this.invoice.expenseReportName = expReport.reportName;
          this.isNewExpReport = false;
          this.creatingNewExpReport = false;
          this.isCreateExpReportItemShown = false;
          this.expClaims = [...this.expClaims, { id: result.id, reportName: expReport.reportName }];
          this.save('Expense Report');
        }
      )
  }

  updateExpReportStatus() {
    if (this.invoice.expenseReportName != null) {
      const expReport = tryGetObjectByProperty(this.expClaims, 'reportName', this.invoice.expenseReportName) as SyncedExpClaims;
      this.isNewExpReportLoaded = expReport ? true : false;
      this.isNewExpReport = !expReport && !this.invoice.supplierId ? true : false;
      this.isCreateExpReportItemShown = this.isNewSupplier;
      this.newExpReport.reportName = this.invoice.expenseReportName;
      if (this.invoice.expenseReportId != null && this.expClaims.length > 0 && this.expClaims.find(p => p.id == this.invoice.expenseReportId) != null) {
        this.invoice.expenseReportName = this.expClaims.find(p => p.id == this.invoice.expenseReportId).reportName;
      }
    }
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value;

    if (!reg.test(input)) {
      this.invoice.amountDue = Number(Number(input).toFixed(2))
      event.preventDefault();
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  saveReportBankDetails() {
    this.invoice.paymentStatus = InvoicePaymentStatus.AwaitingPayment;
    if (this.invoice.accountID != null && this.invoice.accountID != "") {
      this.invoice.paymentAccountNumber = this.invoice.accountID;
    }
    if (this.invoice.transactionDate != null) {
      this.invoice.paymentDate = this.invoice.transactionDate;
      this.saveInvoiceWithDelay('PaymentDate');
    }
  }

  closeReportMatch() {
    this.showMatchDialog = false;
    this.maindivHeight = false;
  }

  matchUnreconciledData() {
      this.updateSuggestedMatches();
      if(this.isEditable) { 
        this.publishInvoice();
      }
  }

  ignoreUnreconciledData() {
    this.updateSuggestedMatches();
  }
  updateSuggestedMatches() {
    let unreconciledReportIds = this.invoice.unreconciledData.map(a => a.unreconciledReportId).join(',');
    var model = {
      unreconciledReportIds: unreconciledReportIds,
      invoiceId: this.invoice.id,
      organizationId: this.invoice.organisationId
    }
    this._invoiceService.updateSuggestedMatches(model)
      .pipe(take(1))
      .subscribe(
        () => {
          this.showMatchDialog = false;
          this.maindivHeight = false;
          this.unreconciledReportIds = unreconciledReportIds;
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.ErrorInbox, '', `Error!`);
        });
  }

  calculateDivHeight() {
    var element = document.getElementById('floating-div');
    let totalheight = 0;
    if (element != null) {
      var floatingdivHeight = parseInt(document.defaultView.getComputedStyle(element, "").getPropertyValue("height"));

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      var windowheight = window?.innerHeight;
      if (isMobile) {
        totalheight = windowheight - floatingdivHeight - 130;
      } else {
        totalheight = windowheight - floatingdivHeight - 135;
      }


      return {
        'height': totalheight + 'px',
        'overflow': 'auto'
      }
    }
  }

  onChangePaymentStatus() {
    this.setFormValidator(this.invoice.type ? this.invoice.type : this.type);
    this.saveInvoiceWithDelay('Status');
  }

  ngAfterViewInit() {
    this.calculateDivHeight();
    if(this.form)
      this.invoice.required_Fields = this.form.valid;
    else
      this.invoice.required_Fields = true;
  }

  changeSelectedMatch(event, id) {
    this.invoice.unreconciledData.forEach((num, index) => {
      this.invoice.unreconciledData[index].selected = false;
    });
    this.invoice.unreconciledData.find(x => x.unreconciledReportId == id).selected = !event;
    if(this.invoice.unreconciledData.findIndex(x => x.selected == true) > -1)
    {
      this.isMatchPublish = true;
      this.selectedUnreconciledReportId = id;
      let item = this.invoice.unreconciledData.find(x => x.unreconciledReportId == this.selectedUnreconciledReportId);
        if (item != null) {
          this.invoice.paymentStatus = InvoicePaymentStatus.AwaitingPayment;
          this.invoice.paymentDate = item.transactionDate;
          if (this.IsQuickBook) {
            this.MyBankAccount = this.bankAccountList.find(x => x.accountID === item.referenceId);
            this.invoice.paymentAccountNumber = item.referenceId;
          } else {
            this.MyBankAccount = this.bankAccountList.find(x => x.accountID === item.accountID);
            this.invoice.paymentAccountNumber = item.accountID;
          }
          this.saveInvoiceWithDelay('PaymentDate');
        }
    }
    else {
      this.isMatchPublish = false;
      this.selectedUnreconciledReportId = "";
    }
  }

  checkIfMobileDevice() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      this.isMobileDevice = true;
    } else {
      this.isMobileDevice = false;
    }
  }
}
