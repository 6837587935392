import { Component, OnInit, HostListener, OnDestroy, Output, EventEmitter, ViewChild, ElementRef,Renderer2  } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { UserService } from '../../data/service/user.service';
import { OrganizationService } from '../../data/service/organization.service';
import { ToasterMessageService } from '../../shared/toaster-message/toaster-message.service';
import { SharedService } from 'src/app/core/service/shared.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SuppliersService } from 'src/app/data/service/suppliers.service';
import { debounceTime, distinctUntilChanged, switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/service/auth/auth.service';
import { SearchInvoicesResponse, SearchParams, SearchResponse, SearchResult, SearchSuppliersResponse } from 'src/app/data/model/search-result';
import { DomSanitizer } from '@angular/platform-browser';
import { SearchService } from 'src/app/data/service/search.service';
import { ToasterTypes } from 'src/app/core/enum/toaster-type.enum';
import { SidebarType } from 'src/app/modules/bookkeeping/enums/sidebar-type';
import { xeroParamsForConnection, QuickBookConnection } from 'src/environments/environment';
import { Guid } from 'guid-typescript';
import { StripePaymentService } from 'src/app/data/service/stripePayment.service';
import { finalize, repeat} from 'rxjs/operators';
import { RefreshLog_Type } from '../../data/enums/common-enum';
import { ManageIntercom } from '../../core/util/manage-intercom';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  showToolTipDelay: number = 400;
  subscriptions: Subscription[] = [];

  currentUser: any;
  sortedOrganizationList: any = [];
  organizationList: any;
  searchValue: string = null;
  isDropdownOpen = false;

  // Search
  isSearchResultsShown: boolean = false;
  searchTerm: string = '';
  searchResponse = {} as SearchResponse;
  isSearchBarShown: boolean = false;
  
  isSidebarVisible: boolean = false;
  isSettingsSidebar: boolean = false;
  isUploadSidebar: boolean = false;
  types = SidebarType;

  private organizationIdFromUrl: string = null;
  private generalPageSize: number = 4;
  private billPagesSize: number = 5;
  private billPage: number = 1;
  private supplierPageSize: number = 5;
  private supplierPage: number = 1;
  private _searchTerms = new Subject<SearchParams>();
  private _unsubscribe$ = new Subject<void>();
  gameSeed: any;

  @ViewChild('billsSection') billsSection: ElementRef;
  @ViewChild('suppliersSection') suppliersSection: ElementRef; 

  @Output() showSideNav: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:click', ['$event'])
  clickout() {
    this.isDropdownOpen = false;
  }

  constructor(
    public organizationService: OrganizationService,
    private userService: UserService,
    public spinnerService: SpinnerService,
    public sharedService: SharedService,
    private router: Router,
    private suppliersService: SuppliersService,
    private readonly _toasterMessageService: ToasterMessageService,
    private readonly _authService: AuthService,
    private readonly _sanitizer: DomSanitizer,
    private readonly _searchService: SearchService,
    private readonly _activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private stripePaymentService: StripePaymentService

  ) {
    this.setSearch();
    // this.renderer.listen('window', 'click', (e: Event) => {
    //   // this.clickCloseSearchBarIcon();
    // });
  }

  toggleSidebar(sidebar: SidebarType) {
    if (sidebar === SidebarType.settings)
      this.isSettingsSidebar = !this.isSettingsSidebar;
    else if (sidebar === SidebarType.upload) 
      this.isUploadSidebar = !this.isUploadSidebar;

    this.isSidebarVisible = !this.isSidebarVisible;
  }

  ngOnInit() {
    ManageIntercom.updateintercode(true);
    const tempOrgIdParam = this._activatedRoute.snapshot.queryParamMap?.get('organizationId');

    if (tempOrgIdParam && Guid.isGuid(tempOrgIdParam)) {
      this.organizationIdFromUrl = tempOrgIdParam;
      this.organizationService.selectedOrganization = this.organizationIdFromUrl;
      sessionStorage.organizationId = this.organizationIdFromUrl;
    }

    this.subscriptions.push(this.organizationService.organizationList.subscribe(data => {
      if (data) {
        this.organizationList = data.sort((a, b) => a.orderRank < b.orderRank ? 1 : a.orderRank > b.orderRank ? -1 : 0);
        this.sortedOrganizationList = this.organizationList.slice(0, 8);
        this.setData();
      } else {
        this.organizationList = [];
      }
    })); 
    this.subscriptions.push(this.userService.loggedUserDetails.subscribe(userdata => {
      if (userdata) {
        this.currentUser = userdata;
       this.setUser(userdata);
        this.setData();
      } else {
        this.currentUser = [];
      }
    }));
  }

  setUser(response) {
    var id= this._activatedRoute.snapshot.queryParamMap?.get('organizationId');
    if (id != null) {
      sessionStorage.UserName = response.name;
      sessionStorage.Id = response.id;
      sessionStorage.UserId = response.userId;
      localStorage.setItem('UserName', response.name);
      localStorage.setItem('UserId', response.userId);
      localStorage.setItem('Id', response.id);
      localStorage.setItem('SelectedOrganization', response.organizationId);
      localStorage.setItem('Email', response.email);
      localStorage.setItem('CreatedAt', response.created_date);
      this.userService.setUserRoleData(response.data.user.role);
      this.userService.upUserDetails = JSON.stringify(response);

      if (response.organizationId) {
        sessionStorage.organizationId = response.organizationId;
        sessionStorage.selectedOrganization = response.organizationId;
        this.AddandUpdatesupplierXeroData(response.organizationId, response.email)
      }
    }
  }

  setData() {
    if (this.organizationList && this.organizationList.length > 0 && this.organizationService.selectedOrganizationName === null) {
      const getOrganization = this.organizationList.find(x => x.organisationID.toLowerCase() == this.currentUser?.organizationId.toLowerCase());
      if (getOrganization) {
        this.organizationService.selectedOrganization = getOrganization.organisationID;
        this.organizationService.selectedOrganizationName = getOrganization.organisationName;
        this.organizationService.OrganizationUseAzureAPI = getOrganization.useAzureAPI;
        this.organizationService.selectedOrganizationDomen = getOrganization.accountingIntegration == 0 ? 'Xero' : 'QBO';
        this.currentUser.organizationId = getOrganization.organisationID;
        this.currentUser.picture = '../../../assets/img/user-icon.png';
        this.organizationService.getSelectedOrganization(this.organizationService.selectedOrganization);
        this.organizationService.selectedOrganizationDateFormat = getOrganization.dateFormat;

        if (this.organizationIdFromUrl && this.organizationIdFromUrl != getOrganization.organisationID) {
          this.selected(this.organizationIdFromUrl);
        } 
      }
    } else {
      const getOrganization = this.organizationList.find(x => x.organisationID.toLowerCase() == this.organizationService.selectedOrganization.toLowerCase());
      if (getOrganization) {
        this.organizationService.selectedOrganization = getOrganization.organisationID;
        this.organizationService.selectedOrganizationName = getOrganization.organisationName;
        this.organizationService.OrganizationUseAzureAPI = getOrganization.useAzureAPI;
        this.organizationService.selectedOrganizationDomen = getOrganization.accountingIntegration == 0 ? 'Xero' : 'QBO';
        this.organizationService.getSelectedOrganization(this.organizationService.selectedOrganization);
        this.organizationService.selectedOrganizationDateFormat = getOrganization.dateFormat;

        if (this.organizationIdFromUrl && this.organizationIdFromUrl != getOrganization.organisationID) {
          this.selected(this.organizationIdFromUrl);
        } 
      }
    }
  }

  openDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.clearSearchValue();
  }

  selected(organizationId: any): void { 
    const getOrganization = this.organizationList.find(x => x.organisationID.toLowerCase() == organizationId.toLowerCase());
    
    if (getOrganization) {
      getOrganization.orderRank += 1;
      this.organizationService.selectedOrganizationName = getOrganization.organisationName;
      this.organizationService.selectedOrganization = getOrganization.organisationID;
      this.organizationService.selectedOrganizationDateFormat = getOrganization.dateFormat;
      this.organizationService.selectedOrganizationDomen = getOrganization.accountingIntegration == 0 ? 'Xero' : 'QBO';
    }

    sessionStorage.organizationId = organizationId;
    sessionStorage.selectedOrganization = organizationId;
    sessionStorage.selectedOrganizationName = this.organizationService.selectedOrganizationName;
    this.organizationService.getSelectedOrganization(this.organizationService.selectedOrganization);
    this.SetOrganizationLoggedIn(this.currentUser.userId, organizationId);
    this.GetOrganizationLoggedIn(this.currentUser.id, organizationId);
    this.getOrganizationConnectedStatus();
    this.AddandUpdatesupplierXeroData(organizationId,this.currentUser.email);
    this.getOrganizationDocumentLimit();
    
    if (!this.organizationIdFromUrl) {
      this.router.navigate(['/dashboard'], { replaceUrl: true });
    }
    this.SyncStripePlansToDatabase();
    this.isDropdownOpen = false;
    let element: HTMLElement = document.getElementById("orgdropdown") as HTMLElement;
    element.click();
    this.clearSearchValue();
    this.sortedOrganizationList = this.organizationList.sort((a, b) => a.orderRank < b.orderRank ? 1 : a.orderRank > b.orderRank ? -1 : 0).slice(0, 8);
  }

  SetOrganizationLoggedIn(UserId, OrganisationId) {
    this.organizationService.InsertUpdateUserLastLoggedOrganization(UserId, OrganisationId)
      .subscribe((data: any) => {
      }, err => {

      });
  }

  GetOrganizationLoggedIn(UserId, OrganisationId) {
    this.organizationService.GetOrgUserLoggedOrganization(UserId, OrganisationId)
      .subscribe((data: any) => {
        localStorage.setItem("roleId", data.role);
        this.userService.setUserRoleData(data. role);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([this.router.url]);
        });
      }, err => {

      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  AddandUpdatesupplierXeroData(orgId,email) {
    this.suppliersService.AddandUpdatesupplierXeroData(orgId,email, RefreshLog_Type.Auto)
      .pipe(take(1))
      .subscribe(data => {
        
      });
  }

  signOut() {
    this._authService.signOut();
    this.gameSeed = setInterval(() => {//your code to navigate }, 100);

      clearInterval(this.gameSeed);
      this.gameSeed = null;
  });
    this.router.navigate(['auth/login'], {skipLocationChange: true, replaceUrl: false});
  }

  redirectToHelpCenter() {
    window.open('https://help.syncedhq.com/', '_blank');
  }

  searchOrganization(value: string) {
    this.searchValue = value;
    if (this.searchValue && this.searchValue != '') {
      this.sortedOrganizationList = this.organizationList
        .filter(a => a.organisationName.toLowerCase().includes(this.searchValue.toLowerCase()))
        .sort((a, b) => a.orderRank < b.orderRank ? 1 : a.orderRank > b.orderRank ? -1 : 0);;
    } else {
      this.sortedOrganizationList = this.organizationList.slice(0, 8);
    }
    
    if (this.sortedOrganizationList.length > 8) 
      this.sortedOrganizationList = this.sortedOrganizationList.slice(0, 8);
  }

  clearSearchValue() {
    this.searchValue = null; 
    setTimeout(() => {
      this.sortedOrganizationList = this.organizationList.slice(0, 8); 
    }, 100);
  }

  mainSearch(data: string) {
    if (!data || !data.trim()) { 
      setTimeout(() => {
        this.searchResponse = null;
        this.isSearchResultsShown = false;
      }, 1000);
      return;
    }

    const searchParams = {
      organizationId: this.organizationService.selectedOrganization,
      searchTerm: data,
      page: 1,
      pageSize: this.generalPageSize
    } as SearchParams;

    this._searchTerms.next(searchParams);
  }

  showMoreBills() {
    if (this.billPage * this.billPagesSize >= this.searchResponse.numberOfBills)
      return;

    this.billPage += 1;

    const searchParams = {
      organizationId: this.organizationService.selectedOrganization,
      searchTerm: this.searchTerm,
      page: this.billPage,
      pageSize: this.billPagesSize
    } as SearchParams;

    this._searchService.getMainSearchInvoicesResults(searchParams)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(
        (data: SearchInvoicesResponse) => {
          if (data!= null) {
            this.setRowCount(data.bills);
            this.searchResponse.bills = [...this.searchResponse.bills, ...data.bills];
            this.searchResponse.numberOfBills = data.numberOfBills;

            if (data?.bills?.length > 0) {
              setTimeout(() => {
                this.billsSection.nativeElement.scrollBy({
                  top: data.bills?.length * 60,
                  left: 0,
                  behavior: 'smooth'
                })
              }, 100);
            }
          }
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message)
        }
      );
  }

  showMoreSuppliers() {
    if (this.supplierPage * this.supplierPageSize >= this.searchResponse.numberOfSuppliers)
      return;

    this.supplierPage += 1;

    const searchParams = {
      organizationId: this.organizationService.selectedOrganization,
      searchTerm: this.searchTerm,
      page: this.supplierPage,
      pageSize: this.supplierPageSize
    } as SearchParams;

    this._searchService.getMainSearchSuppliersResults(searchParams)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(
        (data: SearchSuppliersResponse) => {
          this.setRowCount(data.suppliers);
          this.searchResponse.suppliers = [...this.searchResponse.suppliers, ...data.suppliers];
          this.searchResponse.numberOfSuppliers = data.numberOfSuppliers;

          if (data?.suppliers?.length > 0) {
            setTimeout(() => {
              this.suppliersSection.nativeElement.scrollBy({
                top: data.suppliers?.length * 60,
                left: 0,
                behavior: 'smooth'
              })
            }, 100);
          }
        },
        (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message)
        }
      );
  }

  goToInvoiceDetails(entity: SearchResult) {
    this.isSearchResultsShown = false;
    this.router.navigateByUrl('/inbox').then(() => {
      this.router.navigate([`/inbox/viewInvoice/${entity.entityId}`], { replaceUrl: true });
    });
  }
  
  goToSupplierDetails(entity: SearchResult) {
    this.isSearchResultsShown = false;
    this.router.navigateByUrl('/suppliers').then(() => {
      this.router.navigate([`/suppliers/${entity.entityId}`], { queryParams: {companies: entity.title, groupIds: '', page: 1, pageSize: 20}, replaceUrl: true});
    });
  }

  selectMathcedText(text: string, term: string) {
    if (!text)
      return '';

    let result: string = null;
    let termLength: number = term.length;
    let textLength: number = text.length;
    let startIndex: number = text.toLowerCase().indexOf(term.toLowerCase());

    if (startIndex == 0) {
      result = `<span style="font-weight: 800;">${text.substring(startIndex, startIndex + termLength)}</span>`;
      result += `<span>${text.substring(startIndex + termLength)}</span>`;
    } else if (startIndex > 0 && startIndex <= textLength) {
      result = `<span>${text.substring(0, startIndex)}</span>`;
      result += `<span style="font-weight: 800;">${text.substring(startIndex, startIndex + termLength)}</span>`;
      result += `<span>${text.substring(startIndex + termLength)}</span>`;
    } else {
      result = `<span>${text}</span>`;
    }

    return this.sanitizeHtml(result);
  }

  clickShowSearchBarIcon() {
    this.isSearchBarShown = !this.isSearchBarShown;
  }

  clickCloseSearchBarIcon() {
    this.searchTerm = '';
    this.isSearchResultsShown = false;
    this.isSearchBarShown = false;
  }
  stripHTML(HTMLText: string): string {
    const reg = /<[^>]+>/gi;
    return HTMLText.replace(reg, "");
  }

  private setSearch() {
    this._searchTerms
      .pipe(
        takeUntil(this._unsubscribe$),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchParam: SearchParams) => {
          return this._searchService.getMainSearchResult(searchParam);
        })
      ).subscribe({
        next: (data: SearchResponse) => {
          this.searchResponse = data;
          this.searchResponse.bills.forEach((x) => x.title = this.stripHTML(x.title))
          this.searchResponse.suppliers.forEach((x) => x.title = this.stripHTML(x.title))  
          this.setRowCount(this.searchResponse.bills);
          this.setRowCount(this.searchResponse.suppliers, 2);
          this.isSearchResultsShown = true;
          this.billPage = 1;
          this.supplierPage = 1;
        },
        error: (e: Error) => {
          this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', e.message)
        }
      });
  }

  private sanitizeHtml(html: string) {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  private setRowCount(rows: SearchResult[], shift: number = 0) {
    for (let i = 0, n = rows.length; i < n; i++) {
      rows[i].rowCount = ((i + shift) % 6) + 1;
    }
  }

  private getOrganizationConnectedStatus() {
    this.organizationService.GetOrganisationsDetailsByUsersLoginId(this.organizationService.selectedOrganization)
    .subscribe((data: any) => {
      if (!data?.data?.organisationConnected) {
        if (data.data.domain == 'Xero') {
          this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, 'Error', `'${data.data.syncOrgName}' is not connected to ${data.data.domain}. Please, <a  class=\"toastr-link\" href=\"${xeroParamsForConnection.xeroOauth2Link}\">refresh</a> connection!`);
        } else {
          data.data.domain = 'QuickBooks';
          this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, 'Error', `'${data.data.syncOrgName}' is not connected to ${data.data.domain}. Please, <a  class=\"toastr-link\" href=\"${QuickBookConnection.QuickBookOauth2Link}\">refresh</a> connection!`);
        }
      }
    }, err => {
      this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.statusText);
    });
  }

  private getOrganizationDocumentLimit(){
    this.organizationService.GetOrganisationsDocumentLimit(this.organizationService.selectedOrganization)
    .subscribe((data: any) => {
      if(data.orgCounts > data.totalDocument)
      {
        this._toasterMessageService.displayToasterState(ToasterTypes.ErrorWithHtml, null,
          `You’ve exceeded your monthly document limit. Upgrade your subscription. <a  class=\"toastr-link\" href=\"/setting/billing\">Upgrade</a>`);
      }
    }, err => {
      this._toasterMessageService.displayToasterState(ToasterTypes.Error, 'Error', err.statusText);
    });
  }

  SyncStripePlansToDatabase() {
    this.stripePaymentService.SyncSubscriptionsToDatabase("StripePayment/SyncSubscriptionsToDatabase?organisationId=" +sessionStorage.organizationId+"&userId=" + this.currentUser.userId)
      .pipe(take(1),
        finalize(() => {
        }))
      .subscribe((response: any) => {
      }, err => {

      });
  }

  openSupport() {
    ManageIntercom.updateintercode(false);
  }
}
